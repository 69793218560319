@media(min-width:601px){
.classandactions {
  display: flex;
  flex-direction: column;
  width: 93vw;
  align-items: center;
  margin-left: 7vw;
  height: fit-content
}
.anythingineed {
  font-size: 2.2vw;
  margin-top: 15vh;
  font-weight: bold;
}
.greenrow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 70vw;
  height: fit-content;
  gap: 2vw;
}
.bluerow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 70vw;
  height: fit-content;
  gap: 2vw;
  margin-bottom: 2vw;
}
.purplerow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 70vw;
  height: fit-content;
  gap: 2vw;
  margin-bottom: 2vw;
}
.actions {
  height: 25vh;
  width:fit-content;
  max-width:30vh;
  cursor:pointer;
}
.outeractions{
  background-color: #dcd9d6;
  display:flex;
  flex-direction: row;
}
.emotionscolumn{
  display:flex;
  flex-direction: column;
}
.imgTextBoxRow{
  display:flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
}
.submitaction{
  width: 10vw;
  height: 5vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: white;
  background-color: #4cc68d;
}
.othertextbox{
  height: 2.5vh;
  width: 12vw;
}
}
@media(max-width:600px){
  .classandactions {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    align-items: center;
  }
  .anythingineed {
    font-size: 4vw;
    margin-top: 15vh;
    font-weight: bold;
  }
  .greenrow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: fit-content;
    gap: 4vw;
    margin-top: 4vw;
  }
  .bluerow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: fit-content;
    margin-top: 4vw;
    gap: 4vw;
    /* margin-bottom: 4vw; */
  }
  .purplerow {
    margin-top: 4vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: fit-content;
    gap: 4vw;
    /* margin-bottom: 4vw; */
  }
  .actions {
    height: 21vh;
    width: auto;
  }
  .outeractions{
    background-color: #F5FBFF;
    display:flex;
    flex-direction: row;
  }
  .emotionscolumn{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .imgTextBoxRow{
    display:flex;
    flex-direction: column;
    height: fit-content;
    align-items: center;
  }
  .submitaction{
    width: 20vw;
    height: 5vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
    color: white;
    background-color: #4cc68d;
  }
  .othertextbox{
    height: 2.5vh;
    width: 40vw;
  }
  }
  