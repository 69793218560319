.carousel-indicators [data-bs-target] {
    border-radius: 100%;
    width: 10px;
    height: 10px;
    margin-bottom: 21%;
    
}

.card {
    background-color: #ffffff;
    border-radius: 1.5rem;
    margin-bottom: 30%;
    padding: 2rem;
    width: 66%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

.vector {
    height: 3.4rem;
    width: 3.8rem;
    margin-bottom: 1em;

  }  

.card .quote-text {
    color: #000000bf;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 25px;
    width: 100%;
  }
  
  .card .user {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: normal;
  }
  
  .card .user-class {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: normal;
  }

  .carousel-item {
    position: relative;
  }
  
  .carousel-overlay img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  
  .carousel-overlay .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
  
  @media (min-width: 768px) and (max-width: 1200px) {
    .card {
      width: 50%;
    }
  }