:root {
    font-size: 62.5%; /* 10px base for easier rem calculations */
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    }

  .footer-links a {
      text-decoration: none;
      color: #333;
      margin: 0 10px;
  }

  .footer-links img {
      height: 20px;
  }

  
  html, body {
    width: 100%;
  }
  
  body {
    font-size: 1.6rem; /* 16px default font size */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    width: 100%;
    max-width: 100%;
    height: 100vh;
  }
  
  .container1, .container2 {
    width: 100%;
    max-width: 100%;
  }
  
  .container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }

  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  #input_label_div_email{
    align-self: flex-start; 
    text-align: left;
    margin-right: auto;
    /* margin-left:5.5em; */
    padding-bottom: 0.3em;
  }

  #input_label_div_password{
    align-self: flex; 
    text-align: left;
    margin-right: auto;
    margin-left:5.5em;
    padding-bottom: 0.3em;
  }

  .bottom-text {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
    margin-bottom: 1em;
  }
  
  .container2 {
    display: none;
  }

  .input-group{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .field {
    font-size: 1.6rem;
    font-family: "Inter-Bold", Helvetica;
    color: #555555;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-align: left;
  }

  .input .form-control {
    border: 0.1rem solid #000000;
    border-radius: 0.8rem;
    height: 4.5rem;
    width: 35.2rem;
    font-size: 1.6rem;
    font-family: "Inter-Regular", Helvetica;
    color: #555555;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .btn-parent,
  .btn-primary {
    height: 3.8rem;
    width: 32.2rem;
    background: transparent;
    margin-bottom: 1em;
    border-radius: 0.8rem;
    border: 0.1rem solid #000000;
    cursor: pointer;
  }
  
  .top-text {
    width: 100%;
    padding-bottom: 0.5em;
    padding-top: 0.5em; 
  }
  
  .top-text .text-wrapper {
    color: #555555;
    font-family: "Inter-Bold", Helvetica;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .logo-image-top {
    height: 14.7rem;
    width: 14.3rem;
    display: block;
  }
  
  .line {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .text-center {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 1.6rem;
  }
  
  .bottom-text a {
    text-decoration: underline;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.6rem;
    font-weight: 400;
  }

  .bottom-text p {
    margin-bottom: 0;
  }

  .btn-primary {
    background-color: #135aaa;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.6rem;
    font-weight: 700;
    margin-top: 1em;
    cursor: pointer;
  }

  
  .button img {
    height: 2rem;
    width: auto;
    margin-right: 1rem;
  }
  
  .button-text {
    font-size: 1.4rem;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    color: #000;
  }
  
  .text-center{
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.6rem;
    font-weight: 700;
  }
  
  .text-center::before,
  .text-center::after {
    content: "";
    flex: 1;
    height: 0.1rem;
    background-color: #BBBBBB;
    margin: 0 0.5em;
  }

  #line_with_or{
    margin-bottom:1em;
  }

  #email{
    height: 3.8rem;
    width: 32.2rem;
    font-size: 1.4rem;
    font-weight: 400;
  }
  /* .text-wrapper{
  } */

  #password{
    height: 3.8rem;
    width: 32.2rem;
    font-size: 1.4rem;
    font-weight: 400;
  }

  /* carousel section */
  .carousel-caption{
    margin-bottom:5em;
    padding:0em;
    width: 40em;

  }

  /* ******************************* Media Queries ************************************************************************************************* */

  /* Tablet Screens */
  @media (max-width: 600px) {
    .text-center::before,
    .text-center::after {
      width: 140px;
    }

    .bottom-text{
      display: none;
    }
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    .text-center::before,
    .text-center::after {
      width: 140px;
    }
  }

  /* Tablet screens */
  @media (min-width: 768px) {
    .auth-container {
      flex-direction: row;
    }
  
    .container1,
    .container2 {
      width: 50%;
      /* height: 100vh; */
    }
  
    .container2 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  /* Tablet Screens */
  @media (min-width: 992px) {
  
    .content {
      max-width: 50rem;
    }
  }
  
  /* Laptop Screens */
  @media (min-width: 1201px) {
    .text-center::before,
    .text-center::after {
      width: 140px;
    }

    /* .top-text .text-wrapper {
      font-size: 2.3rem;
    } */
  }
  