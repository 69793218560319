.centered-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.7vw;
  margin: 1vh 0;
  color: #333;
}
 
.emotions-summary-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.4vw;
  margin: 20px 0 0px;
} 

.active-component-container {
  margin-top: 1vh;
  background-color: white;
  width: 70vw;
  height: fit-content;
}

.custom-tooltip-img {
  width: 2vw;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  width: 87%;
  position: fixed;
  bottom: 20px;
  /* left: 27%; Adjust this value to align with your layout */
  left: 12vw;
  padding: 0 20px;
  box-sizing: border-box;
  border-width: 1px;
  /* background-color: white; Add a background color to ensure visibility */
  z-index: 1000; /* Ensure buttons appear above other content */
}


.navigation-buttons button {
  padding: 0.5vw 2vh;
  font-size: 1vw;
  font-weight: bold;
  background-color: transparent;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  border-color: black;
  border-width: 1px;
}

.navigation-buttons button:hover {
  background-color: gray;
}

nav {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

nav button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

nav button.active {
  font-weight: bold;
  text-decoration: underline;
  color: black;
}

nav button:hover {
  color: black;
}

@media (max-width: 600px) {
  .emotions-summary-title {
    font-size: 5vw;
    margin: 20px 0;
    text-transform: uppercase;
    display: none;
  }
  /* .big5-header {
    font-size: 2vh;
  } */
  .centered-title {
    text-align: center;
    font-weight: bold;
    font-size: 5vw;
    margin-top: 4vh;
    margin-bottom: 0;
    color: #333;
  }
  .active-component-container {
    margin-top: 0;
    background-color: white;
    width: 100%;
    height: 76vh;
    margin-bottom: 4vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .navigation-buttons {
    width: 100%;
    left: 0;
    z-index: 0;
    bottom: 10px;
  }
  .navigation-buttons button {
    font-size: 4vw;
    padding: 1vw 4vw;
  }
  nav button {
    margin: 0;
  }
}
@media (min-width: 601px)  and (max-width: 1024px) {
  .emotions-summary-title {
    font-size: 1.8vw;
  }
}