@media (min-width: 601px) {
  .outermostrow {
    background-color: #F4F4F3;
    width: 97vw;
    margin-left: 5vw;
    min-height: 100vh;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .welcomeMessageTop {
    width: 100%;
    text-align: center;
    margin-top: 5vh;
    font-weight: bolder;

  }

  .homepageTopDiv {
    background-color: white;
    border: 2px solid #5c9dd6;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 60vw;
    height: fit-content;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 3vh;
    margin-bottom: 1vh;
  }

  .homepageBottomDiv {
    background-color: white;
    border: 2px solid #5c9dd6;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 60vw;
    min-height: 22vh;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 1vw;
    margin-bottom: 3vh;
  }

  #achievementDisplayGrid {
    display: flex;
    justify-content: center;
    flex-direction: row;
    /* gap: 10px; */
    align-items: flex-start;
    /* margin-top: 2vh; */
  }

  #homePageCoin{
    height: 4.5vw;
    width: auto;
  }

  #homePageStreak{
    height: 4.5vw;
    width: auto;
  }

  #homePageBadge{
    height: 4.4vw;
    width: auto;
  }

  .homePageAchievementImageItem {
    width: calc(33.333% );
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* this is the actual image */
  .homePageAchievementDisplayImage {
    max-height: 15vh;
    object-fit: cover;
  }

  .homePageAchievementImageLabel {
    margin: 20px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 13px;
    align-items: start;
    width: 17vw;
    font-weight: bolder;
    /* position: absolute; */

  }

  .blueBackgroundSubtitleHome {
    background-color: #256aa6;
    color: white;
    width: 100%;
    justify-content: center;
  }

  .blueBackgroundSubtitleHeaderHome{
    margin-top:3vh;
    margin-bottom: 1vh;
    font-size: 2.5vh;
    font-weight: bold;
    text-align: center;
  }
  .blueBackgroundSubtitleHeaderHome2{
    margin-bottom:2vh;
    font-size: 2vh;
    font-weight: bold;
    text-align: center;
  }

  #heartMindWellnessGif {
    height: 26vw;
    width: auto;
    margin-bottom: 0vh;
  }

  .castlebigger {
    margin-top: 1vh;
    width: 45vw;
    height: 70vh;
    border: 3px solid black;
  }
  .castle {
    width: 40vw;
    height: 60vh;
    border: 3px solid black;
    margin-top: 2vh;
  }
  .littlecastlerow {
    margin-top: 1vh;
    margin-left: 5vw;
    margin-right: 4vw;
    margin-bottom: 8vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: baseline;
  }
  .littlecastle {
    width: 15vw;
    margin-top: 2vh;
    margin-left: 1vw;
    margin-right: 1vw;
    height: fit-content;
  }
  .buildcastle {
    background-color: #f8f2ee;
    align-items: center;
    display: flex;
    width: 80vw;
    height: fit-content;
    flex-direction: column;
  }

  .welcome {
    font-weight: 100vw;
    margin-top: 2vh;
    font-size: 4vw;
  }

  .hometext {
    font-size: 2vw;
  }

  .wellnessButtons {
    height: 7vh;
    width: 20vw;
    font-size: 1.4vw;
    background-color: #256aa6;
    color: white;
    border-radius: 15px;
    align-items: center;
  }

  #homepageTopDivBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #homepageTopDivWellnessButton {
    height: 7vh;
    width: 20vw;
    font-size: 1.4vw;
    background-color: #256aa6;
    color: white;
    border-radius: 15px;
    margin-bottom: 1vw;
    font-weight: bold;
    margin-top: 0vh;
    cursor: pointer;
  }

  #yourAchievementsHeader {
    font-size: 22px;
    margin-bottom: 1vh;
    margin-top: 1vh;
    font-weight: bold;
  }

  #practiceEncouragementParagraph {
    text-align: center;
    font-size: 18px;
  }
  .volume-home {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 5vw;
  }
  .volume-up, .volume-mute {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-left: 1vw;
    margin-bottom: 1vw;
    background-color: #256aa6;
    border-radius: 15px;
    border: 2px solid #333;
    height: 7vh;
    width: 4vw;
  }
  
  .volume-home-icon {
    /* margin: 0 0.2em; */
    font-size: 2vw;
    color: white;
    
  }
}
@media (max-width: 600px) {
  .outermostrow {
    background-color: #fff;
    height: fit-content;
    flex-direction: row;
    justify-content: center;
    display: flex;
    /* margin-bottom: 17vh; */
  }

  /* top div elements */

  .homepageTopDiv {
    background-color: white;
    border: 2px solid #5c9dd6;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 90vw;
    height: fit-content;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 7vh;
  }

  .blueBackgroundSubtitleHome {
    background-color: #256aa6;
    color: white;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    height: fit-content;
  }

  .blueBackgroundSubtitleHeaderHome{
    margin-top:2vh;
    font-size: 6vw;
    font-weight: bold;
    text-align: center;
  }
  .blueBackgroundSubtitleHeaderHome2 {
    margin-bottom: 2vh;
    font-size: 2vh;
    text-align: center;
}

  .welcomeMessageTop {
    width: 100%;
    text-align: center;
    margin: 3vh;
  }

  #homepageTopDivBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #homepageTopDivWellnessButton {
    height: 6vh;
    width: 50vw;
    font-size: 5vw;
    background-color: #256aa6;
    color: white;
    border-radius: 10px;
    font-weight: bold;
  }

  /* bottom div elements */

  .homepageBottomDiv {
    background-color: white;
    border: 2px solid #5c9dd6;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 90vw;
    height: fit-content;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 1vh;
  }
  #yourAchievementsHeader {
    margin-bottom: 1vh;
    margin-top: 1vh;
    font-weight: bold;
    font-size: 2.5vh;
  }
  #practiceEncouragementParagraph {
    text-align: center;
    font-size: 2vh;
  }
  .homePageAchievementImageLabel {
    margin: 1vw 0;
    margin-bottom: 1vh;
    text-align: center;
    font-size: 2vh;
    align-items: start;
    width: 25vw;
    font-weight: bolder;
  }

  #achievementDisplayGrid {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
  }

  .homePageAchievementImageItem {
    text-align: center;
  }

  /* legacy castle css */

  .castlebigger {
    margin-top: 1vh;
    /* width: fit-content; */
    height: 45vh;
    border: 3px solid black;
  }
  .castle {
    margin-top: 1vh;
    /* width: fit-content; */
    height: 45vh;
    border: 3px solid black;
  }
  .littlecastlerow {
    margin-top: 1vh;
    margin-left: 5vw;
    margin-right: 4vw;
    margin-bottom: 8vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: baseline;
    height: 0vh;
  }
  .littlecastle {
    width: 15vw;
    margin-top: 2vh;
    margin-left: 1vw;
    margin-right: 1vw;
    height: fit-content;
    height: 0vh;
  }
  .buildcastle {
    background-color: #f8f2ee;
    align-items: center;
    display: flex;
    width: 80vw;
    height: 100vh;
    flex-direction: column;
  }

  .welcome {
    font-weight: 100vw;
    margin-top: 4vh;
    font-size: 8vw;
  }
  .hometext {
    margin-top: 2vh;
    font-size: 2.8vw;
  }

  .wellnessButtons {
    margin-top: 2vh;
    height: 5vh;
    width: 25vw;
    font-size: 2.2vw;
    color: white;
    background-color: #256aa6;
    border-radius: 15px;
    align-items: center;
    cursor: pointer;
  }

  #heartMindWellnessGif {
    height: 30vh;
    width: auto;
    margin-top: 1vw;
  }

  .homePageAchievementDisplayImage {
    height: 13vw;
    width: auto;
  }
  .volume-home {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vh;
    margin-left: 6vh;
  }
  .volume-up, .volume-mute {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 2vw;
  }
  
  .volume-home-icon {
    margin: 0 0.2em;
    font-size: 2em;
  }
  
  .volume-label {
    font-size: 2vh;
    margin: 0;
    text-align: center;
    margin-top: 10px;
  }
}
@media (min-width: 601px)  and (max-width: 1024px) {
  .homepageTopDiv {
    width: 70vw;
    margin-top: 0;
  }
  .homepageBottomDiv {
    width: 70vw;
  }
  #homepageTopDivWellnessButton {
    height: 4vh;
    width: 20vw;
  }
  .blueBackgroundSubtitleHeaderHome2 {
    font-size: 18px;
  }
  .blueBackgroundSubtitleHeaderHome {
    font-size: 22px;
  }
}
