/* CommonStyles.css */

.wellness-success,
.emotions-log {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    font-size: 2rem;
    
}
.helpful-practices-box {
    margin-bottom: 40px;
}
.class-info-box,
.emotion-selection-box,
.timeframe-selection-box,
.helpful-practices-box,
.percentage-selection-box,
.student-list-box {
    border: 0.125rem solid #ccc;
    border-radius: 0.5rem;
    padding: 1rem;
    background-color: #f9f9f9;
}
.student-list-box {
    margin-bottom: 5vh;
}
.load-more,
.load-more button {
    padding: 0.5vw;
    font-size: 1.2vw;
}
.load-more button:hover {
    background-color: gray;
}
class-info-text-one,
class-info-text-three,
.class-info-text {
    color: #666;
    margin: 0;
    font-size: 2rem;
    text-transform: uppercase;
}
class-info-text-one strong,
class-info-text-three strong,
.class-info-text strong {
    color: #333;
    font-weight: bold;
}

.emotion-header,
.timeframe-header,
.practices-header,
.student-list-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.timeframe-grid-container-studentdata {
    width: 100%;
    margin: 20px 0;
}

.timeframe-grid-studentdata {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.timeframe-item-studentdata {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: calc(14.28% - 10px);
    height: 15vh;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    transition: all 0.3s ease;
    text-align: center;
    border: 1px solid #ccc;
    cursor: pointer;
}

.timeframe-item-studentdata.selected {
    border: 2px solid black;
    background-color: #C9E2FB;
}

.timeframe-item-studentdata svg {
    margin-top: 1vh;
    font-size: 24px;
}

.timeframe-item-studentdata p {
    margin: 0;
    line-height: 1.2;
}

.timeframe-item-studentdata .time-display-studentdata {
    font-size: 14px;
    font-weight: bold;
}

.timeframe-item-subheader-studentdata {
    margin-top: 5px;
    color: #888;
    font-size: 12px;
}
.emotions-log-display {
    overflow-y: scroll;
    height: 30vh;
    border-radius: 8px;
}
.select-search-data{
    margin-top: 1vh;
    height: 30vh;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);}
.message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-icon {
    font-size: 4rem;
    margin-bottom: 1rem;
    color: #6c757d;
}

.message-text {
    font-size: 1.6rem;
    color: #343a40;
    text-align: center;
    margin: 0;
}

@media (max-width:600px){
    .emotions-log-display {
        overflow-y: scroll;
        height: 18vh;
        border-radius: 8px;
    }
    .wellness-success,
    .emotions-log {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        font-size: 1rem;
        width: 100%;
    }
    .timeframe-grid-studentdata {
        /* display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        overflow-x: scroll;
        gap: 2vw; */

        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 10px;
        border: 2px solid #ccc;
        grid-gap: 10px;
    }

    .timeframe-item-studentdata {
        /* display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 30vw;
        height: 20vh;
        box-sizing: border-box;
        border-radius: 5px;
        transition: all 0.3s ease;
        text-align: center;
        border: 1px solid #ccc; */

        border-radius: 0;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.3s ease;
        flex-direction: row;
        display: flex;
        text-transform: uppercase;
        margin-bottom: 0;
        align-items: center;
        justify-content: start;
        width: 100%;
        height: auto;
    }

    .emotion-header
    {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 1rem;
    }
    .class-info-text-one,
    .class-info-text-three,
    .class-info-text {
        font-size: 1.5rem;
        /* white-space: nowrap; */
    }
    .class-info-text-one {
        color: #666;
        margin: 0;
        text-transform: uppercase;
        width: 25vw;
        white-space: nowrap;
        /* overflow-wrap: anywhere; */
    }
    .class-info-text-three {
        color: #666;
        margin: 0;
        text-transform: uppercase;
        width: 55vw;
        white-space: nowrap;
        /* overflow-wrap: anywhere; */
    }
    .timeframe-item-subheader-studentdata {
        display: none;
    }
    /* .timeframe-item-studentdata p {
        font-size: 4vw;
    } */
    .timeframe-item-studentdata .fontawesome-time-icon {
        margin-top: 0;
        font-size: 20px;
    }
    .timeframe-item-studentdata svg {
        margin-right: 2vw;
        margin-top: 0;
    }
    .timeframe-item-studentdata .time-display-studentdata {
        font-size: 4vw;
        font-weight: bold;
    }
    .practice-item {
        margin: 0;
    }
    .practice-images-container-student-data{
        padding: 0;
    }
    .timeframe-grid-container-studentdata{
        margin: 0;
    }
    .emotion-selection-box {
        overflow-x: scroll;
    }
    .load-more,
    .load-more button {
        padding: 5px;
        font-size: 4vw;
        border-width: 1px;
    }
}