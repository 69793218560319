@media(min-width:601px){
  .text {
    font-size: 2.2vw;
    margin-top: 15vh;
    font-weight: bold;
  }
  .outercolumnbreathe{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93vw;
    margin-left: 7vw;
    height: 100vh;
}
.gif {
  height: 30vmax;
  margin-top: 5vh;
}
.outerbreath1{
  background-color: #dcd9d6;
  display: flex;
  flex-direction: row;
}
}
@media(max-width:600px){
  .text {
    font-size: 6vw;
    margin-top: 15vh;
    font-weight: bold;
  }
  .outercolumnbreathe{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    height: 100vh;
  }
  .gif {
    height: 48vh;
    margin-top: 5vh;
  }
  .outerbreath1{
    background-color: #F5FBFF;
  }
}

