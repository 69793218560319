@media (min-width: 601px) {
  .schoolrowsuper {
    margin-left: 2vw;
  }
  .classrowsuper {
    margin-left: 11vw;
  }
  .currentrowsuper {
    margin-left: 11.5vw;
  }
  .previousrowsuper {
    margin-left: 8vw;
  }
  .filterprofiledatasuper {
    margin-top: 0vh;
    margin-left: 2vmax;
    height: 3vmax;
    width: 12vmax;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 1vmax;
  }
}
@media (max-width: 600px) {
  .schoolrowsuper {
    margin-left: 1vw;
  }
  .classrowsuper {
    margin-left: 14vw;
  }
  .currentrowsuper {
    margin-left: 16vw;
  }
  .previousrowsuper {
    margin-left: 7vw;
  }
  .filterprofiledatasuper {
    margin-top: 0vh;
    margin-left: 1vw;
    height: 3.5vh;
    width: 20vw;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 1vmax;
  }
}
