.maincontainer {
  flex: 1;
  width: 60vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.provisioningError {
  color: #fff;
  font-size: 2vmax;
}

.goBackLink {
  color: #fff;
  font-size: 2vmax;
}

.goBackLink:hover {
  color: rgb(105, 156, 209);
  cursor: pointer;
}
