.custom-tooltip {
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    max-width: 300px;
    max-height: 400px;
    overflow-y: auto;
    text-transform: capitalize;
  }
  
  .tooltip-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .emotion-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
  
  .emotion-name {
    font-weight: bold;
    font-size: 16px;
  }
  
  .scroll-area {
    max-height: 200px;
    overflow-y: auto;
  }
  
  .student-list {
    width: 100%;
    border-collapse: collapse;
  }
  
  .student-list th,
  .student-list td {
    padding: 5px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  .student-list th {
    font-weight: bold;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  
  /* Styling the scrollbar */
  .scroll-area::-webkit-scrollbar {
    width: 6px;
  }
  
  .scroll-area::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .scroll-area::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  
  .scroll-area::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .tooltip-summary {
  margin-bottom: 10px;
  padding: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.tooltip-summary p {
  margin: 5px 0;
  font-size: 13px;
  line-height: 1.4;
}

.scroll-area {
  max-height: 200px;
  overflow-y: auto;
}

.scroll-area > p {
  margin-bottom: 5px;
  font-weight: bold;
}
@media(max-width:600px){
  .tooltip-summary {
    display: none;
  }
  .student-list {
    display: table;
    /* table-layout: fixed; */
    /* width: 100%; */
  }
  .student-list th, .student-list td {
    display: table-cell;
    border-bottom: 1px solid #eee;
    padding: 0.5vw;
    text-align: left;
    background-color: white;
    /* width: 70%, 30%; */
  }
  .student-list th {
    font-weight: bold;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    text-align: center;
  }
  .scroll-area {
    max-height: 30vh;
    overflow-y: auto;
  }
}