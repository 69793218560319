.navigation-bar {
  display: flex;
  justify-content: center;
  margin: 2vh 0;
  /* margin-bottom: 20px; */
}

.nav-button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2vw;
  color: #808080; /* Gray color for unclicked state */
  transition: color 0.3s ease;
  cursor: pointer;
}

.nav-button.active {
  font-weight: bold;
  text-decoration: underline;
  background-color: transparent;
  color: #000000; /* Black color for clicked state */
}

.nav-button:hover {
  color: #000000; /* Black color on hover */
}

@media (max-width: 600px) {
  .nav-button {
    font-size: 5vw;
    font-weight: 400;
    margin:0;
    white-space: nowrap;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    padding: 10px;
    text-decoration: none;
    color: white;
  }
  .nav-button:hover {
    color: white;
  }

  .nav-button.active {
    font-weight: bold;
    text-decoration: underline;
    background-color: transparent;
    /* color: #000000; */
    border: none;
    color: white;
  }
  .navigation-bar {
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
    border-bottom: 2px solid #555555;
    -webkit-overflow-scrolling: touch;
    display: flex;
    width: 100vw;
    -ms-overflow-style: none;
    scrollbar-width: none;
    justify-content:flex-start;
    margin: 0;
    /* background-color: white; */
    margin-top: 7vh;
    background-color: #256aa6;
  }
  .navigation-bar::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 601px)  and (max-width: 1024px) {
  .nav-button {
    font-size: 1.8vw;
  }
}