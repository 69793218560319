.filter-container {
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 2vh;
}

.filter-row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
}

.filter-group {
  display: flex;
  flex-direction: column;
}

.filter-group-who {
  flex: 1;
  margin-right: 10px;
}

.filter-group-when {
  flex: 1;
}
.filter-group-labels{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: start;
}
.filter-group-label {
  font-size: 1vw;
  color: #333;
  margin-bottom: 5px;
}

.filter-dropdowns {
  display: flex;
  height: 5vh;
}

.filterprofiledatasuper {
  padding: 0 10px;
  font-size: 1vw;
  color: black;
  border: 1px solid #ccc;
  border-radius: 12px;
  background-color: white;
  flex: 1;
  margin: 0;
  width: 100%;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-caret-down-fill" viewBox="0 0 16 16"><path d="M7.247 11.14l-4.796-5.481C2.12 5.253 2.562 4.5 3.5 4.5h9c.938 0 1.38.753.548 1.159l-4.796 5.481a1 1 0 0 1-1.504 0z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
  text-align: left;
  height: 100%;
}

.filterprofiledatasuper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.filterprofiledatasuper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.filterprofiledatasuper:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0,123,255,.25);
  position: relative;
  z-index: 1;
}

@media (max-width: 600px) {
  .filter-group-label {
    font-size: 2vh;
    color: #333;
    margin-bottom: 0;
    padding: 5px;
  }
  .filter-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    margin-bottom: 1vh;
    flex-direction: column;
  }
  
  .filter-dropdowns {
    display: flex;
    height: 5vh;
    width: 100%;
  }
  
  .filter-container {
    width: 100%;
    padding: 1rem;
    margin: 0;
  }
  
  .filterprofiledatasuper {
    padding: 0 10px;
    font-size: 3.8vw;
    color: black;
    border: 1px solid #ccc;
    border-radius: 0;
    background-color: white;
    flex: 1;
    margin: 0;
    width: 100%;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-caret-down-fill" viewBox="0 0 16 16"><path d="M7.247 11.14l-4.796-5.481C2.12 5.253 2.562 4.5 3.5 4.5h9c.938 0 1.38.753.548 1.159l-4.796 5.481a1 1 0 0 1-1.504 0z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    text-align: left;
    height: 100%;
  }
  .filter-group-who {
    margin-right: 0;
  }
}