.audiocolumn{
    background-color: #dcd9d6;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10vw;
    height: 100vh;
}
.audiotitle{
    margin-left: 5vw;
    margin-top: 25vh;
    font-size: 2vw;
    font-weight: bold;
}
.audiobuttonrow{
    margin-left: 5vw;
    margin-top: 10vh;
}
.volumeicon{
    /* color: white; */
    opacity: 0.2;
    margin-top: 10vh;
    margin-left: 5vw;
    font-size:10vw;
}
@media(max-width:600px){
    .audiocolumn{
        background-color: #F5FBFF;
    }
}