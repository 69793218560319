@media (min-width: 601px) {
  .choosedistrictandschool {
    width:30vw;
    margin-bottom: 5vh;
    font-size: 2vw;
    font-weight: bold;
  }

  .biggerselects {
    /* margin-top: 1vh; */
    margin-bottom: 5vh;
    margin-left: 4vw;
    height: 6vh;
    width: 20vw;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 1.2vw;
  }
}

@media (max-width: 600px) {
  .choosedistrictandschool {
    margin-bottom: 3vh;
    font-size: 3vw;
    font-weight: bold;
  }
  .biggerselects {
    /* margin-top: 1vh; */
    margin-bottom: 2vh;
    margin-left: 4vw;
    /* border-color: #d6d4cf; */
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    height: 4vh;
    width: 25vw;
    font-size: 2vw;
  }
  
}
