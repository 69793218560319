.chart-container {
    width: 100%;
    height: 100%; /* Adjust height as needed */
    border: 1px solid lightgray;
    padding-top: 10px;
    box-sizing: border-box;
}

.chart-title {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 10px;
}

@media (max-width: 600px) {
    .recharts-bar text {
        display: none;
    }
}
  