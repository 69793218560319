.next-steps-container {
    font-family: Arial, sans-serif;
    max-width: 55vw;
    margin: 20px auto;
    /* padding: 20px; */
    height:fit-content;
}

.main-header {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
}

.three-ways-subheader {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}
.quick-tips {
    position: relative;
    margin-bottom: 2vh;
    width: 60px;
    height: 60px;
}
  
.tooltip-text {
    visibility: hidden;
    width: 60vw;
    background-color: white;
    text-align: left;
    padding: 10px;
    font-size: 16px;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
}
  
.quick-tips:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
  
.step-container {
    display: flex;
    margin-bottom: 30px;
}

.icon-column {
    flex: 0 0 80px;
    /* display: flex; */
    justify-content: center;
    align-items: flex-start;
}

.text-column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.step-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    display: inline-flex;
    align-items: center;
}

.step-content {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
}
.step-content p {
    margin-bottom: 0;
}
.icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgba(91, 156, 214, 0.2);
    cursor: pointer;
}

.fa-icon {
    font-size: 24px;
    color: #223649;
}

.header-icon-circle {
    width: 30px;
    height: 30px;
    margin: 0 8px;
}

.header-icon {
    font-size: 16px;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
}

.practice-images-container-row-format{
    display: flex;
    flex-wrap: nowrap;
    gap: 5%;
    margin-left: 5%;
    flex-direction: row;
    
}

.emotions-felt-display-container {
    display: flex;
    flex-wrap: wrap;
    flex-wrap: nowrap;
    gap: 5vw;
    margin-left: 7%;
}

.emotion-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.emotion-image-container {
    position: relative;
    cursor: pointer;
    /* transition: transform 0.3s ease; */
}

.emotion-image-container.hovered {
    transform: scale(1.1);
}

.next-steps-practice-item{
    flex-direction: row;
    align-items: center;
    text-align: center;
}


.emotion-image-next-steps {
    height: 15vh;
    width: auto;
    object-fit: contain;
}

.emotion-count {
    text-align: center;
    margin-top: 5px;
}

.practice-image-container {
    position: relative;
    width: auto;
    padding: 0 2vh;
    cursor: pointer;
}

.practice-image-container.hovered {
    transform: scale(1.1);
}
.practice-image-next-steps{
    height: 15vh;
    width: auto;
    object-fit: contain;
}
.step-example {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height ease, opacity 0.3s ease; 
}    
.step-content p:hover ~ .step-example {
    max-height: 150px;
    opacity: 1;
}

.next-steps-practice-item {
  position: relative;
}

.practice-image-container {
  position: relative;
}
.emotion-icon-stretch {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.emotion-image-container {
    position: relative;
}
.hover-text {
  position: absolute;
  background-color: white;
  color: black;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 5px 15px;
  max-width: 250px;
  word-wrap: break-word;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.next-steps-practice-item:hover .hover-text {
  opacity: 1;
}
.emotion-icon-stretch:hover .hover-text {
    opacity: 1;
    z-index: 2;
}

@media (max-width: 600px) {
    .next-steps-container {
        /* margin-bottom: 30vw; */
        max-width: 100vw;
        height: 55vh;
        margin: 0 20px;
        /* overflow-y: auto; */
    }

    .next-steps-practice-item{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .practice-image-next-steps{
        width: 85px;
        height: auto; 
        object-fit: contain;
    }
    .header-icon-circle {
        width: 70px;
        height: 55px;
    }
    .step-content {
        font-size: 1.8vh;
        line-height: 1.5;
        margin-bottom: 15px;
    }
    .step-content p {
        margin-bottom: 0;
    }
    .step-header {
        font-size: 2vh;
    }

    .practice-images-container-row-format {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-left: 0;
        gap: 0;
    }

    .emotion-image-container {
        /* margin-left: -5vw; */
        display: flex;
        flex-wrap: wrap;
        gap: 7vw;
        flex-direction: row;
    }

    .emotion-image-next-steps {
        height: 85px;
        width: auto;
        object-fit: contain;
    }

    .emotions-felt-display-container{
        width: auto;
        left: 20vw;
        text-align: center;
        gap: 3vw;
        margin-left: 0;
        margin-top: 0;
    }
    .practice-image-container {
        padding: 0 1vh;
    }
    .fa-icon {
        font-size: 15px;
    }
    .icon-circle {
        width: 40px;
        height: 40px;
    }
    .icon-column {
        padding-right: 10px;
        flex: 0 0 0;
    }
    .step-container {
        margin-bottom: 0;
    }
    .three-ways-subheader {
        margin-bottom: 10px;
        margin-top: 0;
    }
    .quick-tips {
        width: 40px;
        height: 40px;
    }
    .tooltip-text {
        width: 90vw;
        height: 40vh;
        overflow-y: scroll;
    }
}

@media (min-width: 601px)  and (max-width: 1024px) {
    .practice-image-next-steps{
        height: 10vh;
    }
    .emotion-image-next-steps {
        height: 10vh;
    }
   
    .practice-images-container-row-format {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5vw;
    }
    .emotions-felt-display-container {
        display: flex;
        flex-wrap: wrap;
        gap: 5vw;
        margin-left: 7%;
    }
    
}