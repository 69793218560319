.tabulator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}
.tabulator {
  background-color: transparent;
  width: 500px;
}

.tabulator-header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.tabulator-header {
  background-color: #1464FF;
  color: white;
}
.custom-tabulator {
  border-collapse: collapse;
}

.tabulator-header {
  background-color: #1464FF;
  color: white;
}

.tabulator .tabulator-row {
  transition: background-color 0.3s;
}

.tabulator .tabulator-row:hover {
  background-color: #f1f1f1;
}

.tabulator .tabulator-row.tabulator-selected {
  background-color: #d1e7dd;
}

.tabulator .tabulator-cell {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.tabulator .tabulator-cell input {
  width: 100%;
}

.table-controls {
  margin: 10px 40px;
  display: flex;
  align-items: center;
  width: 100%;
}

.paste-button, .delete-button {
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  background-color: #4cc68d;
  color: #fff;
  cursor: pointer;
}

.clever-button-color {
  background-color: white;
  color: #1464FF;
}

.delete-button {
  margin-left: auto;
}

.paste-button {
  margin-right: auto;
}

.tabulator-container.selected {
  border-color: #1464FF;
}
.clipboard-menu {
  position: absolute;
  bottom: 0;
  left: 8px;
  background-color: #4cc68d;
  box-shadow: 0px 4px 6px rgba(0.1, 0.1, 0.1, 0.3);
  border-radius: 4px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.clipboard-menu button {
  background-color: #4cc68d;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.clipboard-menu button:hover {
  background-color: #012414;
}


@media (max-width: 600px) {
  .table-controls {
    justify-content: center;
    margin-right: 0;
  }
  .tabulator {
    width: 330px;
  }
}
