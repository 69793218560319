@media (min-width: 601px) {
  .limitspreadsheetschools {
    margin-top: 5vh;
  }
  .schoolstoprow {
    display: flex;
    flex-direction: row;
  }
  .chooseaschooltext {
    width: 40vw;
    margin-top: 5vh;
    font-size: 2vw;
    font-weight: bold;
  }
  .addorremoveschool {
    margin-bottom: 5vh;
    font-size: 2vw;
    font-weight: bold;
    width: 30vw;
  }
  .selectschoolrow {
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
  }
  .schoolselect {
    /* align-items: center; */
    margin-top: 4.5vh;
    /* margin-bottom: 3vh; */
    margin-left: 4vw;
    height: 6vh;
    width: 20vw;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 1.2vw;
  }
  .newschooltextbox {
    margin-left: 4vw;
    height: 5vh;
    width: 14vw;
    /* margin-top: 7.2vh; */
    font-size: 1vw;
  }
  .schoolselectdelete {
    /* align-items: center; */
    margin-left: 4vw;
    /* margin-top: 1vh; */
    /* margin-left: 8vw; */
    height: 5.7vh;
    width: 14.5vw;
    /* border-color: #d6d4cf; */
    /* border-radius: 5px 5px 5px 5px; */
    /* text-align: center; */
    /* border-width: 0.1vmax; */
    color: #78746d;
    font-size: 1vw;
  }
  .schoolsheader {
    font-size: 2.5vw;
    font-weight: bold;
    margin-top: 6vh;
  }
}
@media (max-width: 600px) {
  .limitspreadsheetschools {
    font-size: 2.5vw;
    margin-top: 5vh;
    height: 20vh;
    overflow-y: scroll;
  }
  .schoolstoprow {
    display: flex;
    flex-direction: row;
  }
  .chooseaschooltext {
    margin-top: 5vh;
    font-size: 3vw;
    font-weight: bold;
  }
  .addorremoveschool {
    margin-bottom: 3vh;
    font-size: 3vw;
    font-weight: bold;
  }
  .selectschoolrow {
    display: flex;
    flex-direction: row;
  }
  .schoolselect {
    /* align-items: center; */
    margin-top: 4.5vh;
    /* margin-bottom: 3vh; */
    margin-left: 4vw;
    height: 4vh;
    width: 30vw;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 1.8vw;
  }
  .newschooltextbox {
    margin-top: 1vh;
    margin-left: 2vw;
    height: 3vh;
    width: 18vw;
    /* margin-top: 7.2vh; */
    font-size: 2vw;
  }
  .schoolselectdelete {
    margin-top: 1vh;
    /* align-items: center; */
    margin-left: 4vw;
    /* margin-top: 1vh; */
    /* margin-left: 8vw; */
    height: 4vh;
    width: 25vw;
    /* border-color: #d6d4cf; */
    border-radius: 5px 5px 5px 5px;
    /* text-align: center; */
    /* border-width: 0.1vmax; */
    color: #78746d;
    font-size: 2vw;
  }
  .schoolsheader {
    font-size: 4vw;
    font-weight: bold;
    margin-top: 6vh;
  }
}
