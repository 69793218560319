.bluelabel {
  color: #3366cc;
}
.label {
  font-weight: bold;
}

.emojiDropdown {
  display: flex;
  align-items: center;
}
.my-story-header {
  text-align: center;
  font-size: 1.5vw;
}

@media (min-width: 601px) {
  .backgroundlayer {
    height: 100vh;
    width: 80vw;
    margin-left: 10vw;
    background-color: #f4f4f3;
  }
  .profileoutercolumn {
    display: flex;
    flex-direction: column;
    /* margin-left: 12vw; */
    align-items: center;
  }
  .profilecolumn {
    background-color: white;
    width: 63vw;
    height: 75vh;
    margin-left: 8vw;
    margin-top: 2vh;
    border-radius: 5px 5px 5px 5px;
    overflow-y: scroll;
    position: relative;
    margin-bottom: 2vh;
  }

  .faDownload {
    font-size: 1.5vw;
    margin-top: 4px;
    margin-right: 20px;
    /* position: absolute; */
    right: 130px;
    z-index: 1000;
    transition: top 0.3s ease;
    opacity: 60%;
  }

  .faDownload.lower-position {
    top: 25vh; /* Adjust this value as needed */
  }

  .faDownload.lowest-position {
    top: 30vh; /* Adjust this value to position the button even lower */
  }

  .demo-customized-button {
    background-color: white;
    margin-left: 30vw;
    box-shadow: none;
    height: 3vmax;
    width: 14vw;
    border-radius: 5px 5px 5px 5px;
    border-width: 0.1vmax;
    border-color: #d6d4cf;
    color: #78746d;
    font-size: 1vmax;
    text-align: center;
    border-style: solid;
    cursor: pointer;
  }
  .dropwdownrow {
    display: flex;
    flex-wrap: row;
    /* align-items: center; */
  }
  .bargraph {
    background-color: white;
    width: 63vw;
    height: 80vh;
    margin-top: 2vh;
    justify-content: center;
    align-content: center;
  }
  .wellnessSuccess {
    background-color: white;
    width: 63vw;
    height: 50vh;
    justify-content: center;
    align-content: center;
  }
  .questionMark {
    height: 25px;
    width: 25px;
    border: solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: #78746d;
  }
  .historybox {
    border-radius: 25px 25px 25px 25px;
    display: flex;
    flex-direction: row;
    margin-left: 4vmax;
    height: 4vmax;
    width: 55vmax;
    margin-top: 2vmax;
    align-items: center;
    justify-content: space-between;
    font-size: 1vmax;
    font-weight: bold;
  }
  .titletext {
    align-self: center;
    font-size: 1.8vw;
    font-weight: bold;
    margin-top: 6vh;
    margin-left: 6vw;
  }

  .titletextinside {
    align-self: center;
    font-size: 1.8vw;
    font-weight: bold;
    margin-top: 2vh;
    margin-left: 6vw;
  }

  .dropwdownrow {
    display: flex;
  }

  .titledescription {
    align-self: center;
    font-size: 1.5vw;
    /* margin-top: 1vh; */
    margin-left: 6vw;
  }
  .filterprofile {
    /* margin-top: 2vh; */
    margin-left: 3.5vw;
    margin-bottom: 1vw;
    height: 3vmax;
    width: 12vmax;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 1vmax;
  }
  .profileimg {
    margin-left: 2vmax;
    height: 3.5vmax;
  }
  .profilefeelbetter {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1vmax;
    font-weight: normal;
  }
  .profileaction {
    width: 20vmax;
  }
  .profiledate {
    margin-right: 2vmax;
  }
  .profiletime {
    font-size: 1.2vmax;
    font-weight: bold;
  }
  .calendarsizer {
    height: 85vh;
    width: 55vw;
  }
  .custom-tooltip {
    font-size: 1vw;
    background-color: white;
    padding: 1px 1px 1px 1px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid gray;
    border-radius: 1px;
  }
  .filterlabelsimg {
    width: 2vw;
    margin-right: 5px;
  }
}
 
@media (max-width: 600px) {
  .backgroundlayer {
    height: 100vh;
    width: 100vw;
    background-color: #f8f2ee;
  }
  .profileoutercolumn {
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    height: 72vh;
    overflow-y: scroll;
  }
  .profilecolumn {
    background-color: white;
    width: 85vw;
    height: 60vh;
    /* margin-left: 8vw; */
    margin-top: 2vh;
    border-radius: 5px 5px 5px 5px;
    position: relative;
    margin-bottom: 2vh;
  }

  .wellnesspoints {
    height: 50vh;
    overflow-y: scroll;
  }
  .demo-customized-button {
    background-color: white;
    margin-left: 30vw;
    box-shadow: none;
    height: 3vh;
    width: 20vw;
    border-radius: 5px 5px 5px 5px;
    border-width: 0.1vmax;
    border-color: #d6d4cf;
    color: #78746d;
    font-size: 1vmax;
    text-align: center;
    border-style: solid;
  }

  .faDownload {
    font-size: 3vw;
    margin: 20px 5px;
    float: right;
    position: absolute;
    top: 130px;
    right: 20px;
    z-index: 2;
    display: none;
  }

  .faDownload.lower-position {
    top: 25vh;
  }

  .faDownload.lowest-position {
    top: 35vh; /* Adjust this value to position the button even lower */
  }

  .dropwdownrow {
    display: flex;
    flex-wrap: row;
    /* align-items: center; */
  }
  .bargraph {
    background-color: white;
    width: 85vw;
    height: 60vh;
    /* margin-left: 8vw; */
    margin-top: 2vh;
    justify-content: center;
    align-content: center;
  }

  /* .barGraphEmojiLabel{
      display:none;
    } */

  .wellnessSuccess {
    background-color: white;
    width: 85vw;
    height: 50vh;
    /* margin-left: 8vw; */
    justify-content: center;
    align-content: center;
  }
  .historybox {
    border-radius: 25px 25px 25px 25px;
    display: flex;
    flex-direction: row;
    margin-left: 4vmax;
    height: 4vmax;
    width: 75vw;
    margin-top: 2vmax;
    align-items: center;
    justify-content: space-between;
    font-size: 2vw;
    font-weight: bold;
  }
  .titletext {
    align-self: center;
    font-size: 4vw;
    font-weight: bold;
    margin-top: 4vh;
  }
  .titledescription {
    font-size: 2.5vw;
    margin-top: 1vh;
    width: 80vw;
    align-self: center;
    text-align: center;
    margin-left: 0;
  }
  .my-story-header {
    text-align: center;
    font-size: 5vw;
  }

  .barGraphLabel {
    font-size: 1.8vh;
    /* display:none; */
  }

  .filterprofile {
    /* position: absolute; */
    margin-left: 2.5vh;
    margin-bottom: 1vh;
    margin-top: 2vh;
    height: 4vh;
    width: 50vw;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 2vh;
  }
  .filtertext {
    font-size: 2vh;
  }
  .profileimg {
    margin-left: 2vmax;
    height: 3.5vmax;
  }
  .profilefeelbetter {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 1vmax;
    font-weight: normal;
    /* align-content: center; */
    /* align-items: center; */
  }
  .profileaction {
    width: 18vmax;
    margin-left: 1vw;
  }
  .profiledate {
    margin-right: 2vmax;
  }
  .profiletime {
    font-size: 1.8vw;
    font-weight: bold;
  }
  .calendarsizer {
    height: 50vh;
    width: 75vw;
    margin-bottom: 5vh;
  }
  .custom-tooltip {
    font-size: 4vw;
    background-color: white;
    padding: 1px 1px 1px 1px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid gray;
    border-radius: 1px;
  }
  .feelbettertext {
    margin-left: 1.2vw;
  }
  .filterlabelsimg {
    width: 5vw;
    margin-top: 1vh;
  }

  .emotions-summary-title {
    display: none;
  }
}

@media (min-width: 601px)  and (max-width: 1024px) {
    .titledescription {
      font-size: 1.8vw;
    }
  }