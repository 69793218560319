@media (min-width: 601px) {
  .whatdoisee {
    height: 45vh;
  }
  .whatdoihear {
    height: 45vh;
  }
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93vw;
    background-color: #dcd9d6;
    /* justify-content: center; */
    margin-left: 7vw;
    height: 100vh;
  }
  .outer1 {
    background-color: #dcd9d6;
    display: flex;
    flex-direction: row;
  }
  .seeandhearrow {
    /* margin-bottom: 24vh; */
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  /* .seaheartext {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
  } */
  .seeandheartextrow {
    color: #4da95a;
    display: flex;
    flex-direction: row;
    font-size: 2.2vw;
    margin-bottom: 4vh;
    margin-top: 10vh;
    font-weight: bold;
    justify-items: center;
    align-items: center;
  }
  .see {
    margin-top: 0;
  }
  .seetext {
    margin-top: 0;
    color: #5b9cd6;
    /* text-decoration: underline; */
    text-decoration-color: #5b9cd6;
  }
  .heartext {
    margin-top: 0;
    color: #5b9cd6;
    /* text-decoration: underline; */
    text-decoration-color: #4cc68d;
  }
  .focusaway {
    font-size: 1.8vw;
    margin-top: 2vh;
    font-weight: bold;
  }
  .greennumberseeandhear {
    width: 2.5vw;
    height: 6.5vh;
    /* height: fit-content; */
  }
  .timerbar {
    width: 40%;
    margin-top: 5vh;
  }
}
@media (max-width: 600px) {
  .timerbar {
    width: 60vw;
    margin-top: 5vh;
    margin-left: 5vw;
    /* align-self: center; */
  }
  .whatdoisee {
    height: 26vh;
  }
  .whatdoihear {
    height: 26vh;
  }
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background-color: #F5FBFF;
    height: 100vh;
  }
  .outer1 {
    background-color: #F5FBFF;
    display: flex;
    flex-direction: row;
  }
  .seeandhearrow {
    /* margin-top: 15vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* .seaheartext {
    margin-top: 15vh;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
  } */
  .seeandheartextrow {
    color: #4da95a;
    display: flex;
    flex-direction: row;
    font-size: 6vw;
    margin-top: 10vh;
    font-weight: bold;
    justify-items: center;
    align-items: center;
  }
  .see {
    margin-top: 0;
  }
  .seetext {
    margin-top: 0;
    color: #5b9cd6;
    /* text-decoration: underline; */
    text-decoration-color: #5b9cd6;
  }
  .heartext {
    margin-top: 0;
    color: #5b9cd6;
    /* text-decoration: underline; */
    text-decoration-color: #4cc68d;
  }
  .focusaway {
    font-size: 5vw;
    margin-top: 2vh;
    font-weight: bold;
  }
  .greennumberseeandhear {
    width: 6vw;
    height: 4vh;
  }
}
