@media (min-width: 601px) {
  .disclaimercolumn {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: fit-content;
    background-color: #4cc68d;
    align-items: center;
    color: white;
  }
  .bottomdesign2 {
    justify-self: baseline;
    position: absolute;
    /* margin-top: 117vh; */
    /* margin-top: 50%; */
    width: 100vw;
  }
  .bottomdesignrotate {
    position: absolute;
    margin-top: -4vh;
    width: 100vw;
    transform: rotate(180deg);
  }
  .containdimension {
    width: 80vw;
    margin-top: 4vh;
  }
  .disclaimertitle {
    font-size: 2.5vw;
    font-weight: bold;
    margin-top: 12vh;
    position: relative;
  }
  .pleaseanswer {
    font-size: 2vw;
    font-weight: bold;
    margin-top: 4vh;
    position: relative;
  }
  .disclaimerbigtext {
    align-self: baseline;
    margin-right: 65vw;
    font-size: 2vw;
    font-weight: bold;
    margin-top: 2vh;
  }
  .disclaimerlittletext {
    align-self: baseline;
    margin-top: 2vh;
    margin-left: 4vw;
    font-size: 1.25vw;
  }
  .disclaimerlittletextlong {
    align-self: baseline;
    margin-top: 2vh;
    margin-left: 4vw;
    font-size: 1.25vw;
    overflow-y: scroll;
    /* height: 20vh; */
  }
  .disclaimerrow {
    display: flex;
    flex-direction: row;
    justify-items: center;
    font-size: 1.25vw;
    margin-left: 35vw;
    position: relative;
    margin-top: 1.5vh;
  }
  .checkboxtext {
    margin-top: 1vh;
    margin-right: 0.3vw;
  }
  .checkbox {
    margin-top: 1.5vh;
    height: 1.8vh;
    width: 1.8vh;
  }

  .disclaimerbutton {
    margin-top: 5vh;
    /* margin-left: 29vw; */
    color: white;
    font-weight: bold;
    position: relative;
    margin-bottom: 2vh;
    cursor: pointer;
  }
  .iagree {
    margin-left: 2vw;
    margin-top: 1vh;
    margin-right: 0.3vw;
  }
  .disabledbutton {
    margin-bottom: 2vh;
    margin-top: 5vh;
    /* margin-left: 29vw; */
    color: lightgray;
    font-weight: bold;
    position: relative;
    height: 4vh;
    width: 10vw;
    border-radius: 15px;
    cursor: pointer;
  }
}
@media (max-width: 600px) {
  .disclaimercolumn {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: fit-content;
    background-color: #4cc68d;
    align-items: center;
    color: white;
  }
  .bottomdesign2 {
    justify-self: baseline;
    position: absolute;
    /* margin-top: 117vh; */
    /* margin-top: 50%; */
    width: 100vw;
  }
  .bottomdesignrotate {
    position: absolute;
    /* margin-top: 4vh; */
    width: 100vw;
    transform: rotate(180deg);
  }
  .containdimension {
    width: 95vw;
    /* margin-top: 4vh; */
  }
  .disclaimertitle {
    font-size: 4vw;
    font-weight: bold;
    margin-top: 6vh;
    position: relative;
  }
  .pleaseanswer {
    font-size: 2.5vw;
    font-weight: bold;
    margin-top: 2vh;
    position: relative;
  }
  .disclaimerbigtext {
    align-self: baseline;
    margin-right: 75vw;
    font-size: 2.5vw;
    font-weight: bold;
    margin-top: 2vh;
  }
  .disclaimerlittletext {
    align-self: baseline;
    margin-top: 2vh;
    margin-left: 4vw;
    font-size: 2vw;
  }
  .disclaimerlittletextlong {
    align-self: baseline;
    margin-top: 2vh;
    margin-left: 4vw;
    font-size: 2vw;
    overflow-y: scroll;
    height: 20vh;
  }
  .disclaimerrow {
    display: flex;
    flex-direction: row;
    justify-items: center;
    font-size: 2.5vw;
    margin-left: 35vw;
    position: relative;
    margin-top: 1.5vh;
  }
  .checkboxtext {
    margin-top: 1.5vh;
    margin-right: 0.3vw;
  }
  .checkbox {
    margin-top: 1.5vh;
    height: 1.8vh;
    width: 1.8vh;
  }

  .disclaimerbutton {
    margin-top: 4vh;
    height:5vh;
    color: white;
    font-weight: bold;
    position: relative;
    margin-bottom: 4vh;
  }
  .iagree {
    margin-left: 2vw;
    margin-top: 1vh;
    margin-right: 0.3vw;
  }
  .disabledbutton {
    margin-bottom: 3vh;
    margin-top: 4vh;
    height:5vh;
    /* margin-left: 29vw; */
    color: lightgray;
    font-weight: bold;
    position: relative;
    height: 4vh;
    width: 20vw;
    border-radius: 15px;
  }
}
