.seeandhear2 {
    margin-top: 12vmax;
    height: 22vmax;
  }
.inner2{
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 93vw;
    background-color: #5b9cd6;
    margin-left: 7vw;
    height: 100vh;
}  
.outer2{
    display:flex;
    flex-direction: row;
    background-color: #5b9cd6;
}