
@media(min-width:601px){
    .outercongrats{
        display:flex;
        flex-direction:row;
        background-color: #F4F4F3;
        height: 100vh;
    }
    .columncongrats{
        display:flex;
        flex-direction:column;
        width: 60vw;
        height: fit-content;
        align-items: center;
        margin-left: 23.5vw; /* the width of the sidebar is 15 so to center it is (100vw-15-60) + 15 */
        margin-top: 8vh;
        background-color: white;
        border: 2px solid #5C9DD6;
        border-radius: 10px;
        overflow: hidden;
    }
    .fontawesomeLightBulb{
        color: #F4CD8E;
        height: 5vh;
        width:auto;
        margin-right: 1vw;
        /* position: absolute; */
        left: 15vw;
        top: 50%;
        /* transform: translateY(-50%); */
    }

    .blueBackgroundSubtitleCongrats{
        background-color: #256AA6;
        color: white;
        width: 100%;
        height:fit-content;
        text-align: center;
        font-size: 1.4vw;
        font-weight: bold;
        padding-top: 2vh;
    }

    .totalCoinsEarned{
        font-weight: bold;
        font-size: 1.4vw;
        width: 100%;
        text-align: center;
    }

    .wasthishelpful{
        font-size: 1.6vw;
        flex: 1;
    }

    .rowcongrats{
        display:flex;
        flex-direction:row;
        margin-top: 2vh;
        margin-bottom: 3vh;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 20px;
        width: 100%;
    }
    .rowhelpful{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 2vh;
    }
    .buttonHelpfulnessGroup{
        display: flex;
        gap: 10px;
        cursor: pointer;
    }
    .helpfulyes{
        background-color: white;
        border: 2px solid #256AA6;
        width: 10vw;
        margin-right: 2vw;
        height: 5vh;
        border-radius: 10px;
        font-size: 20px;
    }
    .helpfulno{
        border: 2px solid #256AA6;
        background-color: white;
        width: 10vw;
        margin-left: 2vw;
        height: 5vh;
        border-radius: 10px;
        font-size: 20px;
    }
    .coinsFallingSlowGif{
        margin-top: 5vh;
        width: 18vw;
        height:auto;
        /* display:none; */
    }
    .upperCongratsMessage{
        z-index: 0;
        width:10vw;
        margin-left:51vw;        
        text-align: center;
        position:absolute;
        font-size: 1.8vw;
        top:3vh;
    }
    .congratulationscastle{
        height: 75vh;
        width:55vw;
        margin-top: 3vh;
        border: 3px solid black;
    }
}


@media(max-width:600px){
    .outercongrats{
        display:flex;
        flex-direction:row;
        background-color: #F5FBFF;
        height: 100vh;
    }
    .columncongrats{
        display:flex;
        flex-direction:column;
        width: 80vw;
        height: 75vh;
        align-items: center;
        margin-left: 10vw; /* the width of the sidebar is 15 so to center it is (100vw-15-60) + 15 */
        margin-top: 8vh;
        background-color: white;
        border: 2px solid #5C9DD6;
        border-radius: 15px;
        overflow: hidden;
    }
    .fontawesomeLightBulb{
        color: #F4CD8E;
        height: 5vh;
        width:auto;
        /* position: absolute; */
        left: 10vw;
        top: 50%;
        margin-right: 3vw;
        /* transform: translateY(-50%); */
    }

    .blueBackgroundSubtitleCongrats{
        background-color: #256AA6;
        color: white;
        width: 100%;
        text-align: center;
        font-size: 2vh;
        font-weight: bold;
        height:fit-content;
    }

    .totalCoinsEarned{
        font-weight: bold;
        font-size: 4vw;
    }
    .wasthishelpful{
        margin-top: 1vh;
        font-size: 4vw;
        flex: 1;
    }

    .rowcongrats{
        display:flex;
        flex-direction:row;
        margin-bottom: 3vh;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
    }
    .rowhelpful{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 2vh;
        margin-bottom: 2vh;
    }
    .buttonHelpfulnessGroup{
        display: flex;
        gap: 10px;
    }
    .helpfulyes{
        background-color: white;
        border: 2px solid #256AA6;
        width: 24vw;
        margin-right: 2vw;
        height: 5vh;
        border-radius: 15px;
    }
    .helpfulno{
        border: 2px solid #256AA6;
        background-color: white;
        width: 24vw;
        margin-left: 2vw;
        height: 5vh;
        border-radius: 15px;
    }
    .coinsFallingSlowGif{
        margin-top: 5vh;
        width: 40vw;
        height:auto;
    }
    .upperCongratsMessage{
        z-index: 0;
        width:100%;
        text-align: center;
        position:absolute;
        font-size: 4vw;
        top: 0.6vh;
    }
    .congratulationscastle{
        height: 75vh;
        width:55vw;
        margin-top: 3vh;
        border: 3px solid black;
    }
}
