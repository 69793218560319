/* StudentWellnessSuccessData.css */
@import '../CommonStyles.css';

.all-emotions-button {
    margin-top: -6vh;
    margin-bottom: 1vh;
    margin-left: auto;
    padding: 0.625rem 3rem;
    background-color: transparent;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 0.3125rem;
    cursor: pointer;
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
}

.all-emotions-button.selected {
    background-color: #e6f4ea;
    border-color: #4caf50;
}

.all-emotions-button img {
    width: auto;
    height: 4rem;
    margin-right: 2rem;
}

.image-grid-student-data {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 10px 0;
}

.emotion-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0.3125rem;
    transition: all 0.3s ease;
    transform-origin: center center;
}

.emotion-item.selected,
.emotion-item.all-selected {
    transform: scale(1.1);
    opacity: 1;
}

.emotion-item:not(.selected):not(.all-selected) {
    opacity: 0.6;
    transform: scale(0.9);
}

.grid-image {
    width: 6rem;
    height: 6rem;
    cursor: pointer;
    transition: all 0.3s ease;
    object-fit: contain;
}

.grid-image.selected {
    border: none;
}

.emotionEmojiLabel {
    font-size: 1.5rem;
    margin-top: 0.3125rem;
    font-weight: bold;
}

.practice-images-container-student-data {
    display: flex;
    overflow-x: scroll;
    padding: 1rem 0;
    width: 100%;
}

.practice-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1.25rem;
}

.practice-image {
    width: 12vw;
    height: 12vw;
    transition: transform 0.3s ease;
}

.practice-caption {
    font-size: 1.5rem;
    margin-top: 0.625rem;
    font-weight: bold;
    text-align: center;
}

.circle-number-studentdata {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: #4caf50;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    margin-top: -0.5vh;
}

.no-practices-message {
    text-align: center;
    color: #666;
    font-size: 1.25rem;
    padding: 1.25rem;
}

.next-steps-practice-item {
  position: relative;
}

.practice-image-container {
  position: relative;
}

.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.next-steps-practice-item:hover .hover-text {
  opacity: 1;
}


@media(max-width:600px){
    .all-emotions-button {
        margin-left: 0;
        padding: 0;
        background-color: transparent;
        color: #333;
        border: none;
        cursor: pointer;
        font-size: 2vh;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: end;
        transition: all 0.3s ease;
        flex-direction: column;
        text-transform: uppercase;
        text-align: start;
        white-space: nowrap;
        margin-bottom: 0;
    }
    
    .all-emotions-button.selected {
        background-color: transparent;
        /* transform: scale(1.1); */
        margin-left: 2vw;
    }
    .emotion-item.selected,
    .emotion-item.all-selected {
        transform: scale(1);
    }
    
    .all-emotions-button img {
        height: auto;
        width: 17vw;
        margin-right: 0.5rem;
    }
    .practice-image {
        width: 35vw;
        height: auto;
        transition: transform 0.3s ease;
    }
    .emotionEmojiLabel {
        text-transform: uppercase;
        font-size: 2vh;
        margin-bottom: 0;
        margin-top: 0;
    }
    
    .all-emotions-grid {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
    .image-grid-student-data {
        padding: 0;
        gap: 2vw;
        overflow-x: unset;
    }
    .grid-image {
        height: auto;
        width: 6vh;
    }
    .emotion-item {
        justify-content: end;
    }
    .emotion-item.selected {
        transform: none;
    }
    .practice-item {
        margin: 0;
    }
    .practice-images-container-student-data {
        padding: 0;
    }
    
    .practice-caption-student-data {
        font-size: 2vh;
        margin-bottom: 0;
    }
    .practice-caption {
        font-size: 2vh;
    }
    .circle-number-studentdata {
        width: 2.1875rem;
        height: 2.1875rem;
        border-radius: 50%;
        background-color: #4caf50;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.375rem;
        font-weight: bold;
    }
    .hover-text {
        font-size: 2vh;
    }
    
}