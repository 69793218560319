.combined-search-dropdown {
    position: relative;
    width: 97%;
    height: 98%;
  }
  
  .search-input-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 12px;
    background-color: #f9f9f9;
    padding: 0 10px;
  }
  
  .search-input-container.selected {
    background-color: #f0f0f0;
  }
  
  .selected-icon {
    margin-right: 10px;
    font-size: 1.2vw;
    color: #4a4a4a;
  }
  
  .search-input {
    flex-grow: 1;
    height: 100%;
    padding: 0 30px 0 0;
    font-size: 1vw;
    border: none;
    background-color: transparent;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    color: #000;
  }
  
  .search-input:focus {
    outline: none;
  }
  
  .search-input::placeholder {
    color: #999;
  }
  
  .search-input:focus,
  .search-input:not(:placeholder-shown) {
    color: #000;
  }
  
  .options-list {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    max-height: 300px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 12px;
    list-style-type: none;
    padding: 5px 0;
    margin: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .option {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    margin: 5px 10px;
    border-radius: 3px;
  }
  
  .option:hover {
    background-color: #f0f0f0;
  }
  
  .option.indented {
    margin-left: 25px;
  }
  
  .option.indented-twice {
    margin-left: 40px;
  }
  
  .icon {
    margin-right: 10px;
  }
  
  .student-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .no-results {
    display: flex;
  }