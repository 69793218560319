@media (min-width: 601px) {
  .practicewellnesstext {
    font-size: 2vw;
    margin-top: 8vh;
    font-weight: bold;
  }
  .chosenaction {
    margin-top: 8vh;
    height: 50vh;
  }
  .outerperformaction {
    background-color: #dcd9d6;
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
  .altwellness {
    font-size: 2vw;
    color: #4cc68d;
    margin-top: 4vh;
    font-weight: bold;
  }
  .linearprogresspractice{
    width: 40%;
    margin-top: 5vh
  }
}
@media (max-width: 600px) {
  .chosenaction {
    margin-top: 4vh;
    height: 40vh;
  }
  .outerperformaction {
    background-color: #F5FBFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100vh;
  }
  .altwellness {
    font-size: 4vw;
    color: #4cc68d;
    margin-top: 4vh;
    font-weight: bold;
  }
  .practicewellnesstext {
    font-size: 4vw;
    margin-top: 12vh;
    font-weight: bold;
  }
  .linearprogresspractice{
    width: 60vw;
    margin-top: 4vh
  }
}
