.providerButton {
  border: none;
  margin-bottom: 2vh;
  border-radius: initial;
  background: transparent;
}

.providerButton:hover {
  cursor: pointer;
}

@media (min-width: 601px) {
  .outer {
    height: 100vh;
    width: 100%;
    background-color: #4cc68d;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .logo {
    height: 28vh;
    max-width: 100%;
    margin-top: 10vh;
  }
  .signin {
    color: white;
    font-size: 2vw;
    font-weight: bold;
  }
  .bottomdesign {
    width: 100vw;
    /* position: fixed;  */
    /* bottom:0%;   */
    margin-top: auto;
  }
}

@media (max-width: 600px) {
  .outer {
    height: 100vh;

    width: 100%;
    background-color: #4cc68d;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .logo {
    margin-top: 10vh;
    max-width: 80%;
  }
  .signin {
    width: 80vw;
    color: white;
    font-size: 6vw;
    font-weight: bold;
    margin-top: 4vh;
  }
  .bottomdesign {
    width: 100vw;
    position: fixed;
    bottom: 0%;
    min-height: 8vh;
  }
}
