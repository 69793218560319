#SyncScreenParent {
  /* dimensions */
  top: 0;
  right: 0;
  z-index: -1;
  height: 100vh;

  /* flex styling */
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
}

#SyncScreenMain {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#TermsContainer {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0;
  border-radius: 5px;
  flex-direction: row;
  padding-top: 2.5vmax;
  padding-left: 2.5vmax;
  padding-right: 2.5vmax;
  width: calc(100% - 5vmax);
  background-color: #4cc68d;
  justify-content: space-evenly;
}

#SchoolsPreviewParent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

#SyncLoadingScreen {
  left: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  transition: width 0.5s;
  flex-direction: column;
  justify-content: center;
}

#SyncLoadingScreenBackground {
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
}

#SyncLoadingScreenText {
  /* Container style */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Text style */
  color: #4cc68d;
  font-size: 4vmax;
  transition: opacity 1s ease-out;
}

.SyncScreenTitle {
  color: #000;
  margin: 20px 0;
  font-size: 2.5vmax;
}

.CleverSyncButton {
  padding: 3vmin;
  border-radius: 5px;
  transition: filter 0.25s;
  background-color: #4cc68d;
  cursor: pointer;
  /* flex styling */
  display: flex;
  align-items: center;
  justify-content: center;

  /* text styling */
  color: #fff;
  font-size: 2vmax;
}

.SchoolContainer {
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #4cc68d;
}

.SchoolContainer:hover {
  cursor: pointer;
}

.SchoolContainerText {
  margin: 0;
  color: #fff;
  max-width: 20vmax;
  font-size: 2.5vmin;
}
