.congratulations-banner strong {
  font-weight: bold;
}

@media (min-width: 601px)  and (max-width: 1024px) {
  .congratulations-banner {
    background-color: #cae8d9;
    color: black;
    text-align: center;
    padding: 10px;
    font-size: 1.5vw;
    width: 100vw;
    box-sizing: border-box;
    margin-bottom: 1vh;
    position: absolute; 
    left: 0; 
    text-indent: 10%;
  }

  .container{
    height: 5vh
  }
}
@media (min-width: 1025px) {
  .congratulations-banner {
    background-color: #cae8d9;
    color: black;
    text-align: center;
    padding: 10px;
    font-size: 1.1vw;
    width: 100vw;
    box-sizing: border-box;
    margin-bottom: 1vh;
    position: absolute;
    left: 0;
    text-indent: 10%;
  }

  .container{
    height: 8vh
  }
}
@media (max-width: 600px) {
  .congratulations-banner {
    font-size: 4.5vw;
    position: absolute;
    text-align: center;
    background-color: #cae8d9;
    color: black;
    width: 100%;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1;
  }

  .container{
    height: 6vh
  }
  .congratulations-banner strong {
    font-weight: bold;
    margin: 0 1vw;
  }
}