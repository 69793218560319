#MCSchoolHolder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

#SyncSchoolButton {
  /* container style */
  width: 10vw;
  padding: 10px;
  border-width: 2.5px;
  border-style: solid;
  border-radius: 7.5px;
  border-color: #4cc68d;
  background-color: #fff;
  transition: background-color 0.5s, color 0.5s;

  /* text style */
  color: #4cc68d;
  font-size: 2vmin;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.SchoolButton {
  padding: 20px;
  max-width: 20vw;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-width: 2.5px;
  border-radius: 7.5px;
  border-style: solid;
  border-color: #4cc68d;
  background-color: #fff;
  transition: background-color 0.5s;
  cursor: pointer;
}

.SchoolButtonText {
  margin: 0;
  color: #4cc68d;
  text-align: center;
  font-weight: bold;
  font-size: 2.5vmin;
  transition: color 0.5s;
}

.ClassesPreviewHolder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.ClassPreview {
  padding: 20px;
  max-width: 50vw;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 7.5px;
  background-color: #4cc68d;
}

.ClassPreviewText {
  flex: 1;
  margin: 0;
  width: 25vw;
  color: #fff;
  border: none;
  font-weight: bold;
  font-size: 2.5vmin;
  text-align: center;
  background-color: #4cc68d;
}

.CPPlainTextWrapper {
  flex: 1;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  flex-direction: column;
  background-color: #fff;
}

.CPPlainText {
  margin: 0;
  color: #4cc68d;
  font-size: 2.5vmin;
  text-align: center;
}
