.wellnessSuccess {
  background-color: white;
  width: 63vw;
  /* height: 50v; */
  /* margin-top: 2vh; */
  border-radius: 5px 5px 5px 5px;
  /* overflow-y: scroll; */
  position: relative;
  /* margin-bottom: 2vh; */
} 

.wellnessSuccessHeader {
  text-align: center;
  font-weight: bold;
  height: 5%;
  font-size: 1.4vw;
}

.wellness-success-subheader{
  font-size: 1.5vw;
  text-align: center;
}

.divider {
  width: 94%;
  height: 1px;
  background-color: #ccc;
  margin: 0px;
}

.wellnessSuccessBody {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 75%;
}

.image-grid-container {
  margin-top: 2vh;
  width: 30%;
  height: 70%;
  margin-left: 1vw; 
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
}

.all-emotions {
  width: 100%;
  align-items: center;
  font-size: 1.5vw; /* Large font size */
  font-weight: bold;
  cursor: pointer; /* Changes cursor to pointer on hover */
  padding: 10px 0; /* Padding for better click area */
  transition: opacity 0.3s; /* Smooth transition for opacity */
  margin-bottom: 10px; /* Space between "All Emotions" and the grid */
  border-radius: 5px;
  border: 2px solid #ccc; /* Light grey border */
  display: flex;
  justify-content: center;
}

.all-emotions.selected {
  opacity: 1; /* Full opacity when selected */
  /* transform: scale(1.05); */
  background-color: #e6f4ea;
  border-color: #4caf50;
}

.all-emotions:not(.selected) {
  opacity: 0.5; /* Reduced opacity when not selected */
}

.all-emotions-image{
  width: 3vw;
  height: auto;
  object-fit: contain; /* Ensures images are fully visible */
  aspect-ratio: 1 / 1; /* Ensures images are square */
  max-width: 70px; /* Limit the maximum width of images */
  max-height: 50px; /* Limit the maximum height of images */
  transition: transform 0.3s, opacity 0.3s; /* Smooth transition for transform and opacity */
  cursor: pointer; /* Changes cursor to pointer on hover */
  margin-right: 10px;
}

.emotionEmojiDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.emotionEmojiLabel{
  text-align: center;
  font-weight: bold;
  color:#888;
}

.emotionEmojiLabel.selected{
  color:black;
}


.image-grid-mystory {
  margin-top: 2vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1vw; 
  overflow: visible; 
  /* padding: 10px;  */
  width: 16vw;
}

.grid-image-mystory {
  width: 4vw;
  height: auto;
  object-fit: contain; /* Ensures images are fully visible */
  aspect-ratio: 1 / 1; /* Ensures images are square */
  transition: transform 0.3s, opacity 0.3s; /* Smooth transition for transform and opacity */
  cursor: pointer; /* Changes cursor to pointer on hover */
  margin: auto; /* Center the image within its grid cell */
}

.grid-image-mystory.selected {
  transform: scale(1.1); /* Slightly enlarges the selected image */
}

.grid-image-mystory:not(.selected) {
  opacity: 0.5; /* Reduces opacity of non-selected images */
}

.timeframe-grid-container {
  width: 33.33%; /* Takes up one-third of the parent element's width */
  height: 64vh; /*Takes up most of the height */
  margin-left: 10px; 
  margin-right: 10px; 
  display: flex;
  justify-content: center; /* Center the grid horizontally */
  align-items: center; /* Center the grid vertically */
}

.timeframe-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-template-rows: repeat(3, 1fr); /* 3 rows */
  grid-gap: 15px; /* Padding between the elements */
  width: 100%;
  height: 100%;
  padding: 20px; /* Padding inside the grid */
  box-sizing: border-box; /*Include padding and border in the element's total width and height*/
}

.timeframe-item {
  display: flex;
  height: 100%;
  flex-direction: column;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer; /* Pointer cursor on hover */
  transition: transform 0.3s, opacity 0.3s; /* Smooth transition for transform and opacity */
  font-size: 16px; /* Font size */
  text-align: center; /* Center text */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-bottom: 10px;
}

.timeframe-item::first-line {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.timeframe-item .fontawesome-time-icon{
    margin-top: 2vh;
  font-size: 30px;
}

.timeframe-item.selected {
  transform: scale(1.05); /* Slightly enlarges the selected item */
  border: 2px solid black;
}

.timeframe-item:not(.selected) {
  opacity: 0.7; /* Reduces opacity of non-selected items */
  background-color: white !important; 
  border: 2px solid #ccc;
}

.wellnessHelpfulnessDisplayContainer{
  border-radius: 15px;
  border: 2px solid #ccc; /* Light grey border */
}

.timeframe-item-subheader{
  margin-top: 0;
  color: #888;
  margin-bottom: 2vh;
}

/* WellnessPracticeHelpfulnessDisplay css */

.practice-images-grid {
  width: 33.33%; /* Takes up one-third of the parent element's width */
  height: 63vh;  
  display: flex;
  justify-content: center; /* Center the grid horizontally */
  align-items: center; /* Center the grid vertically */
  /* min-width: 250px; Minimum width to ensure visibility */
  min-height: 27vh;
  margin: 10px;
  margin-right: 20px;
  overflow-y: scroll; /* Enable vertical scrolling */
  border: 2px solid #ccc;
  border-radius: 15px;
}
  
.practice-images-container-my-wellness {
  height: 100%;
  /* padding-right: 17px; Compensate for scrollbar width */
  box-sizing: content-box; /* Prevent padding from affecting width */
  flex-direction: column;
}
  
.practice-item-my-wellness {
  width:fit-content;
  text-align: center;
  margin:5%;
}
  
.practice-image-my-wellness {
  width: 9.5vw;
  height: auto;
  object-fit: contain;
  max-width: 200px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}
  
.practice-caption {
  margin: 0;
  font-size: 14px;
}

/* Customize scrollbar for webkit browsers */
.practice-images-container-my-wellness::-webkit-scrollbar {
  width: 8px;
}

.practice-images-container-my-wellness::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.practice-images-container-my-wellness::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.practice-images-container-my-wellness::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.circle-number-container {
  position: relative;
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1vh;
  /* display: none; */
}

.circle-number {
  width: 50px;
  height: 50px;
  background-color: #4CAF50;  /* Green color */
  border-radius: 50%;  /* Makes it circular */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 2vw;
  font-family: Arial, sans-serif;
  /* position: absolute; */
  top: 0;
}
.practice-image-container-whathelps {
  position: relative;
}
.practice-item-my-wellness:hover .whathelps-hover-text {
  opacity: 1;
}
.whathelps-hover-text {
  position: absolute;
  background-color: white;
  color: black;
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  white-space: wrap;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 600px) {
  .wellnessSuccess {
    background-color: white;
    padding: 1rem;
    border-radius: 5px;
    height: min-content;
    width: 90vw;
    justify-content: center;
  }

  .wellnessSuccessHeader {
    font-size: 5vw;
    font-weight: bold;
    text-align: center;
    margin: 2vh 0;
    text-transform: uppercase;
  }

  .divider {
    display: none;
  }

  .circle-number-container {
    display: none;
  }

  .image-grid-container {
    width: 99%;
    overflow-x: scroll;
    flex-direction: row;
    border: 2px solid #ccc;
    margin-left: 0;
    margin: 1vh 0;
  }

  .wellnessSuccessBody {
    display: flex;
    flex-direction: column;
    /* height:fit-content; */
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .practice-images-grid {
    /* margin-bottom: 10vw; */
    /* margin-right: 10px; */
    width: 99%;
    height: auto;
    overflow-x: scroll;
    flex-direction: row;
    min-height: 20vh;
    margin: 1vh 0 0 0;
    border-radius: 0;
    overflow-y: hidden;
  }

  .practice-images-container-my-wellness {
      width: 100%;
      align-content: center;
      /* margin-left: 25vw; */
      display: flex;
      flex-direction: row;
      height: auto;
  }

  .practice-item-my-wellness {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vw;
    /* justify-content: center; */
  }
  /* hover to show data action */
  .practice-image-container-whathelps {
    position: relative;
    /* height: 15vh; */
  }

  .practice-image-container-whathelps::after {
    content: attr(data-action);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .practice-image-container-whathelps:hover::after {
    opacity: 1;
  }
/*  */
  .practice-image-my-wellness {
    height: 15vh;
    width: auto;
    object-fit: contain;
  }

  .practice-caption {
    font-size: 2vh;
    margin-top: 0.5rem;
    white-space: normal;
    text-align: center;
    width: 100%; /* Ensure full width */
  }
  .practice-images-grid::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30px;
    background: linear-gradient(to right, transparent, white);
    pointer-events: none;
    margin-bottom: 15vh;
  }

  /* TimeframeGrid styles */
  .timeframe-grid-container {
    width: 99%;
    margin: 0px;
    height: auto;
  }

  .timeframe-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* This creates two columns */
    padding: 10px;
    border: 2px solid #ccc;
    grid-gap: 10px;
  }

  .timeframe-item {
    /* padding: 0.5rem; */
    border-radius: 0;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex-direction: row;
    display: flex;
    text-transform: uppercase;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
  }

  .timeframe-item p {
    /* margin: 0.25rem 0; */
    font-size: 4vw;
    margin-top: 1rem;
  }

  .timeframe-item-subheader {
    font-size: 2vw;
    display: none;
  }

  .fontawesome-time-icon {
    font-size: 15px;
    /* margin-bottom: 0.5rem; */
    margin-right: 2vw;
  }
  .timeframe-item .fontawesome-time-icon {
    margin-top: 0;
    font-size: 20px;
  }

  /* EmotionsSelector styles */
  .emotions-selector {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .all-emotions.selected{
    background-color: transparent;
    border-color: transparent;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .all-emotions {
    align-items: center;
    font-size: 3vw; /* Large font size */
    font-weight: bold;
    cursor: pointer; /* Changes cursor to pointer on hover */
    transition: opacity 0.3s; /* Smooth transition for opacity */
    justify-content: center;
    flex-direction: column;
    border: none;
    margin-bottom: 0;
    padding: 0;
    width: 35%;
  }
  .all-emotions-label {
    font-size: 2vh;
    text-align: start;
    display: flex;
    text-transform: uppercase;
    white-space: nowrap;
    color: black;
    margin-bottom: 0;
  }
  .all-emotions-image{
    width: 17vw;
    height: auto;
    object-fit: contain; /* Ensures images are fully visible */
    aspect-ratio: 1 / 1; /* Ensures images are square */
    max-width: 20vw; /* Limit the maximum width of images */
    max-height: 20vw; /* Limit the maximum height of images */
    transition: transform 0.3s, opacity 0.3s; /* Smooth transition for transform and opacity */
    cursor: pointer; /* Changes cursor to pointer on hover */
    margin-right: 0;
    margin-left: 0;
  }

  /* Additional styles based on your provided CSS */
  .titledescription {
    font-size: 2vh;
    margin-top: 1vh;
    width: 90vw;
    align-self: center;
    text-align: center;
  }

  .custom-tooltip {
    font-size: 4vw;
    background-color: white;
    padding: 1px 10px;
    border: 1px solid gray;
    border-radius: 1px;
    width: 55vw;
    height: 25vh;
    overflow-x:hidden;
  }

  .filterlabelsimg {
    width: 5vw;
    margin-top: 1vh;
  }
  .grid-image-mystory {
    width: 6vh;
    height: auto;
    object-fit: contain; /* Ensures images are fully visible */
    aspect-ratio: 1 / 1; /* Ensures images are square */
    transition: transform 0.3s, opacity 0.3s; /* Smooth transition for transform and opacity */
    cursor: pointer; /* Changes cursor to pointer on hover */
    margin: 0;
  }
  .wellness-success-subheader{
    font-size: 5vw;
    text-align: center;
    margin-bottom: 0;
  }
  .image-grid-mystory {
    display: flex;
    overflow: visible; 
    width: 16vw;
    gap: 4vw;
}
.emotionEmojiDiv{
  justify-content: end;
}
.whathelps-hover-text {
  width: 30vw;
}

}
@media (min-width: 601px)  and (max-width: 1024px) {
  .wellnessSuccessHeader {
    font-size: 1.8vw;
    margin: 20px 0;
  }
  .wellness-success-subheader{
    font-size: 1.8vw;
    text-align: center;
  }
}