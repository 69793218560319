#ClassShowcase {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.data-grid-container .data-grid .cell .value-viewer {
  background: #fff;
}

#GBB {
  color: "#fff";
  margin-bottom: 0;
  font-size: "2vmin";
  padding: "1.5vmin";
  margin-top: "2vmin";
  margin-left: "2vmin";
  border-radius: "5px";
  align-self: "flex-start";
  background-color: "#4cc68d";
}

#GBB:hover {
  cursor: pointer;
}

#SyncWithCleverButton {
  padding: 2vmin;
  color: #1464ff;
  font-size: 3vmin;
  border-radius: 5px;
  border-style: solid;
  border-width: 2.5px;
  border-color: #1464ff;
  background-color: #fff;
  transition: color 0.5s, background-color 0.5s;
  cursor: pointer;
}

#SyncWithCleverButton:hover {
  color: #fff;
  cursor: pointer;
  background-color: #1464ff;
}

.SSV2CPTitle {
  margin: 0;
  color: #fff;
  font-size: 3vmin;
  font-weight: bold;
  text-align: center;
  align-self: center;
}
.grid-title {
  color: #4cc68d;
  font-size: 2.5vmin;
  font-weight: bold;
  text-align: center;
  align-self: center;
}
.superadmin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2vmin;
  margin-top: 5vmin;
  border-radius: 5px;
  padding: 2vmin;
  text-align: center;
  border-radius: 5px;
  border-width: 2.5px;
  border-style: solid;
  border-color: rgb(76, 198, 141);
  transition: background-color 0.5s;
  cursor: default;
  background-color: rgb(76, 198, 141);
}
.SettingsWrapper:hover {
  cursor: pointer;
}

.SSV2Backdrop {
  display: flex;
  padding: 1vmin;
  border-radius: 5px;
  flex-direction: column;
  background-color: #fff;
  justify-content: space-evenly;
  align-items: center;
}

.SSV2BackdropTeacher {
  display: flex;
  padding: 1vmin;
  flex-wrap: wrap;
  border-radius: 5px;
  flex-direction: row;
  background-color: #fff;
  justify-content: space-evenly;
}

.createnewclasswidth {
  display: flex;
  padding: 1vmin;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: #fff;
  justify-content: space-evenly;
}

.FullNameTooltip {
  /* container style */
  top: 0;
  left: 0;
  opacity: 0;
  padding: 1vmin;
  position: fixed;
  border-radius: 5px;

  /* text style */
  color: #fff;
  font-size: 2vmin;
}

.SelectAllButton {
  color: #fff;
  display: flex;
  padding: 1vmin;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: #4cc68d;
  cursor: pointer;
}

.SelectAllButton:hover {
  cursor: pointer;
}

.SSV2ClassPreview {
  display: flex;
  padding: 2vmin;
  margin: 5vmin;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
}

.SSV2ButtonContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.SSV2Title {
  margin: 4vmin 0;
  font-size: 4vmin;
  font-weight: bold;
}

.SSV2Title2 {
  margin: 2vmin 0;
  font-size: 4vmin;
  font-weight: bold;
}

.SSV2Button {
  /* container style */
  margin-top: 0;
  padding: 2.5vmin;
  margin-left: 5vmin;
  margin-right: 5vmin;
  border-width: 2.5px;
  border-style: solid;
  margin-bottom: 5vmin;
  border-radius: 7.5px;
  /* border-color: #4cc68d; */
  background-color: #fff;

  /* text style */
  color: #4cc68d;
  font-size: 3vmin;
  cursor: pointer;
  /* animation */
  transition: background-color 0.5s, color 0.5s;
}

.EditSchoolInformation {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.AddIconContainer {
  display: flex;
  padding: 1vmin;
  align-self: center;
  border-radius: 50%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}

.AddIconContainer:hover {
  cursor: pointer;
}
.delete-icon {
  color: #fff;
  font-size: 3vmin;
  transition: color 0.3s;
}
.delete-icon:hover {
  color: red;
}
.closeButton {
  position: absolute;
  top: 2vmin;
  right: 2vmin;
  font-size: 2.5vmin;
  background-color: white;
  color: #FF0000;
  padding: 1vmin 2vmin;
  border-radius: 1vmin;
  border: 0.3vmin solid #FF0000;
  cursor: pointer;
  transition: 0.3s;
  min-width: 18vmin;
  min-height: 2vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 0.5vmin 1vmin rgba(0,0,0,0.1);
  z-index: 3001;
}
.save-changes-btn {
  margin: 0;
  color: #4cc68d;
  margin-top: 2vmin;
  border-radius: 5px;
  border-style: solid;
  border-color: #4cc68d;
  padding: 1vmin;
  border-width: 2.5px;
  font-size: 2.5vmin;
  font-weight: bold;
  cursor: pointer;
  max-width: fit-content;
}
.button {
  max-width: fit-content;
}
.go-back-btn {
  color: #fff;
  margin-bottom: 0;
  font-size: 2vmin;
  padding: 1.5vmin;
  margin-top: 2vmin;
  margin-left: 2vmin;
  border-radius: 5px;
  align-self: flex-start;
  background-color: #4cc68d;
  cursor: pointer;
}
/* AddSchoolButton */
.create-school {
  padding: 2vmin;
  font-size: 3vmin;
  text-align: center;
  border-radius: 5px;
  border-width: 2.5px;
  border-style: solid;
  margin-bottom: 2.5vmin;
  transition: background-color 0.5s, color 0.5s;
}

.create-school:hover {
  cursor: pointer;
  color: #fff;
  background-color: var(--btn-border-color);
}
.fixed-overlay {
  top: 0;
  left: 0;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.5s;
  background-color: rgba(0, 0, 0, 0.5);
}
/* Create Class Screen */
.create-class-container {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  overflow: scroll;
  align-items: center;
  justify-content: center;
  z-index: -1;
  transition: z-index 0.1s, background-color 0.5s;
  background-color: rgba(0, 0, 0, 0);
}

.create-class-container.active {
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.class-container {
  width: 40vmax;
  display: flex;
  max-height: 90%;
  overflow: scroll;
  border-radius: 5px;
  position: relative;
  align-items: center;
  flex-direction: column;
  transition: opacity 0.1s, padding 0.1s;
  z-index: -1;
  opacity: 0;
  background-color: transparent;
  padding: 0;
}

.class-container.active {
  opacity: 1;
  padding: 2vmin;
}

.class-container.on-mobile {
  margin-left: 0vmin;
  margin-bottom: 25vmin;
}

.class-container.desktop {
  margin-left: 20vw;
  margin-bottom: 0vmin;
}

.class-container.clever {
  background-color: #1464ff;
}

.class-container.editing-or-creating {
  background-color: #fff;
}

.submit-button.can-submit {
  color: white;
  background-color: #4cc68d;
}

.submit-button.hovering-submit {
  cursor: pointer;
}

.submit-button {
  margin: 0;
  color: #fff;
  background-color: #A0A0A0;
  padding: 1vmin;
  font-size: 2.5vmin;
  border-radius: 5px;
  cursor: default;
  transition: filter 0.2s, cursor 0.2s;
}
.submit-button.highlighted {
  background-color: var(--highlighted-color);
}
.submit-button.disabled {
  filter: grayscale(1);
}
.submit-button.enabled {
  filter: grayscale(0);
  cursor: pointer;
}

.edit-class-name-clever {
  margin: 0;
  color: #fff;
  font-size: 4vmin;
  font-weight: bold;
  text-align: center;
}
.edit-class-name {
  margin: 0;
  color: #4cc68d;
  font-size: 4vmin;
  font-weight: bold;
  text-align: center;
}
.class-name-input-clever {
  flex: 1;
  width: 80%;
  color: #fff;
  padding: 1vmin;
  font-size: 3vmin;
  border-color: #fff;
  border-radius: 5px;
  border-style: solid;
  border-width: 2.5px;
  background-color: #1464ff;
}
.class-name-input {
  flex: 1;
  width: 80%;
  color: #4cc68d;
  padding: 1vmin;
  font-size: 3vmin;
  border-color: #4cc68d;
  border-radius: 5px;
  border-style: solid;
  border-width: 2.5px;
  background-color: #fff;
}
.add-user-title {
  margin: 0;
  color: #4cc68d;
  font-size: 3vmin;
  font-weight: bold;
}
.class-name-label {
  margin: 0;
  color: #4cc68d;
  font-size: 3vmin;
  font-weight: bold;
}
.classname-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.not-synced-warning {
  margin: 0;
  width: 75%;
  color: #4cc68d;
  align-self: center;
  font-size: 2.5vmin;
  text-align: center;
}
/* Classes Showcase */
.admin-subtext {
  color: white;
}
.class-subheader {
  font-size: 2.5vmin;
  font-weight: bold;
  text-align: center;
}
.class-subtext {
  font-size: 2vmin;
  font-weight: normal;
  text-align: center;
}
.view-different-school {
  font-size: 2.5vmin;
  font-weight: bold;
  text-align: center;
  cursor: default;
}

.view-different-school.hovering {
  cursor: pointer;
}
/* Classes Preview */
.user-count {
  display: flex;
  flex-direction: row;
  margin: 0;
  color: #fff;
  font-size: 3vmin;
  font-weight: bold;
  gap: 2vw;
}
.user-title {
  margin: 0;
  color: #fff;
  font-size: 3vmin;
  font-weight: normal;
  text-align: center;
}
.edit-class-container {
  display: flex;
  flex-direction: row;
}
@media (min-width: 601px) {
  .AddIconContainer {
    width: 3vmin;
    height: 3vmin;
  }
  .SSV2CPTitle {
    width: 25vmax;
    /* max-width: 30vmax; */
  }

  .studentscol {
    flex-direction: column;
    display: flex;
    margin-left: 6vw;
    align-items: center;
    padding: 0vw 8vw 0vw 8vw;
    background-color: #f4f4f3;
    min-height: 100vh;
  }
  .studentsheader {
    font-size: 2.5vw;
    font-weight: bold;
    margin-top: 6vh;
  }
  .limitspreadsheet {
    margin-top: 6vh;
    height: 25vh;
    overflow-y: scroll;
  }
  .addstudents {
    margin-top: 10vh;
    background-color: #bd92e4;
    margin-left: 2vw;
    font-size: 1vw;
    height: 4vh;
    width: 10vw;
    border-radius: 15px;
  }
  .seeclassdata {
    margin-top: 6vh;
    background-color: #5b9cd6;
    border-radius: 25px;
    /* border-color: white; */
    /* margin-left: 2vw; */
    height: 7vh;
    width: 20vw;
    font-size: 1.2vw;
  }
  .studentsbuttonrow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .editclassesrow {
    align-self: baseline;
    margin-left: 5vw;
    margin-top: 4vh;
  }
  .chooseaclasstext {
    font-size: 2vw;
    font-weight: bold;
  }
  .classselect {
    /* align-items: center; */
    margin-top: 2vh;
    /* margin-left: 8vw; */
    height: 6vh;
    width: 15vw;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 1.2vw;
  }
  .editclassesrow {
    display: flex;
    flex-direction: row;
  }
  .addnewclass {
    background-color: #bd92e4;
    margin-left: 2vw;
    margin-top: 1vh;
    font-size: 1vw;
    height: 4vh;
    width: 10vw;
    border-radius: 15px;
  }
  .newclasstextbox {
    margin-left: 4vw;
    height: 4vh;
    width: 14vw;
    /* margin-top: 7.2vh; */
    font-size: 1vw;
  }
  .addnewteachertextbox {
    margin-left: 4vw;
    height: 3vh;
    width: 14vw;
    margin-top: 1vh;
    font-size: 1vw;
  }
  .inputandbuttonrow {
    margin-top: 1vh;
    display: flex;
    flex-direction: row;
  }
  .classselectdelete {
    margin-left: 4vw;
    margin-top: 1vh;
    height: 4.5vh;
    width: 14.5vw;
    color: #78746d;
    font-size: 1vw;
  }
  .spreadsheetrow {
    display: flex;
    flex-direction: row;
  }
  .removeastudentselect {
    /* margin-left: 4vw; */
    margin-top: 2vh;
    height: 4.5vh;
    width: 50.5vw;
    color: #78746d;
    font-size: 1vw;
  }
  .removeastudentbutton {
    background-color: #bd92e4;
    margin-left: 2vw;
    margin-top: 2vh;
    font-size: 1vw;
    height: 4vh;
    width: 10vw;
    border-radius: 15px;
    cursor: pointer;
  }
  .selectstudentsrow {
    margin-top: 5vh;
    display: flex;
    flex-direction: row;
  }
  .chooseclasstext {
    width: 40vw;
    font-size: 2vw;
    font-weight: bold;
    margin-top: 5vh;
  }
}
@media (max-width: 600px) {
  .AddIconContainer {
    width: 7.5vmin;
    height: 7.5vmin;
  }
  .SSV2CPTitle {
    width: "50vmax";
    font-size: 4vmin;
    /* max-width: 50vmax; */
  }
  /* .SSV2Backdrop,
  .SSV2BackdropTeacher {
    width: 50vmax;
  } */
  .studentscol {
    flex-direction: column;
    display: flex;
    /* margin-left: 20vw; */
    align-items: center;
  }
  .studentsheader {
    font-size: 3vw;
    font-weight: bold;
    margin-top: 4vh;
  }
  .limitspreadsheet {
    margin-top: 4vh;
    height: 15vh;
    font-size: 2.2vw;
    overflow-y: scroll;
  }
  .addstudents {
    margin-top: 5vh;
    background-color: #bd92e4;
    /* border-radius: 22px; */
    margin-left: 4vw;
    width: 20vw;
    height: 4vh;
    border-radius: 15px;
  }
  .seeclassdata {
    margin-top: 2vh;
    background-color: #5b9cd6;
    border-radius: 25px;
    /* border-color: white; */
    /* margin-left: 2vw; */
    height: 5vh;
    width: 20vw;
    font-size: 1.2vw;
  }
  .studentsbuttonrow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .editclassesrow {
    align-self: baseline;
    margin-left: 5vw;
    margin-top: 4vh;
  }
  .chooseaclasstext {
    font-size: 2.5vw;
    font-weight: bold;
  }
  .classselect {
    margin-top: 2vh;
    height: 5vh;
    width: 30vw;
    border-color: #d6d4cf;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    border-width: 0.1vmax;
    color: #78746d;
    font-size: 2.5vw;
  }
  .editclassesrow {
    display: flex;
    flex-direction: row;
  }
  .addnewclass {
    background-color: #bd92e4;
    margin-left: 2vw;
    margin-top: 1vh;
    font-size: 2.2vw;
    height: 4vh;
    width: 18vw;
    border-radius: 15px;
  }
  .newclasstextbox {
    /* margin-left: 4vw; */
    height: 4vh;
    width: 20vw;
    /* margin-top: 7.2vh; */
    font-size: 2.2vw;
  }
  .addnewteachertextbox {
    /* margin-left: 4vw; */
    height: 4vh;
    width: 20vw;
    margin-top: 1vh;
    font-size: 2.2vw;
  }
  .inputandbuttonrow {
    margin-top: 1vh;
    display: flex;
    flex-direction: row;
  }
  .classselectdelete {
    /* align-items: center; */
    margin-left: 4vw;
    margin-top: 1vh;
    /* margin-left: 8vw; */
    height: 4vh;
    width: 25vw;
    /* border-color: #d6d4cf; */
    border-radius: 5px 5px 5px 5px;
    /* text-align: center; */
    /* border-width: 0.1vmax; */
    color: #78746d;
    font-size: 2.2vw;
  }
  .spreadsheetrow {
    display: flex;
    flex-direction: row;
  }
  .removeastudentselect {
    /* margin-left: 4vw; */
    border-radius: 5px 5px 5px 5px;
    margin-top: 2vh;
    height: 5vh;
    width: 51vw;
    color: #78746d;
    font-size: 2.2vw;
  }
  .removeastudentbutton {
    margin-top: 2vh;
    background-color: #bd92e4;
    margin-left: 4vw;
    width: 20vw;
    height: 4vh;
    border-radius: 15px;
    cursor: pointer;
  }
  .selectstudentsrow {
    /* margin-top:5vh; */
    display: flex;
    flex-direction: row;
  }
  .chooseclasstext {
    font-size: 3vw;
    font-weight: bold;
    margin-top: 4vh;
    width: 50vw;
  }
  .closeButton {
    top: 27vmin;
    right: 4vmin;
  }
  .class-subheader {
    font-size: 3.5vmin;
    width: 90vw;
    display: flex;
    text-align: center;
  }
  .class-subtext {
    font-size: 3vmin;
    width: 90vw;
    display: flex;
    text-align: center;
  }
  .admin-subtext {
    margin: 2vw 0;
    text-align: center;
    font-size: 3.5vmin;
  }
  .class-container {
    width: 94vw;
  }
  .user-count, .user-title, .submit-button, .add-user-title, .class-name-label {
    font-size: 3.5vmin;
  }
  .view-different-school {
    width: 90vw;
    font-size: 3.5vmin;
    margin-bottom: 0;
  }
  .SSV2ClassPreview {
    width: 90%;
  }
  .SSV2Title {
    margin-top: 10vmin;
    font-size: 5vmin;
  }
}
