.howdoifeel {
  margin-bottom: 2.5px;
}

@media (min-width: 601px) {
  .emotionstired {
    width: 5.5vw;
    margin-top: 2.2vh;
    margin-right: 2vw;
    cursor: pointer;
  }
  .emotiontextcalm {
    margin-top: 0.8vh;
    font-size: 1.3vw;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  }
  .emotions {
    width: 5.5vw;
    margin-top: 3vh;
    margin-right: 2vw;
    cursor: pointer;
  }
  .innerfeelcol {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .innerfeelrow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6vw;
  }
  .outerfeel {
    background-color: #dcd9d6;
    display: flex;
    flex-direction: row;
  }
  .howdoifeelrow {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 8vh;
  }
  .greennumber {
    width: 2.5vw;
    height: 6.5vh;
  }
  .howdoifeel {
    color: #4da95a;
    font-size: 2.2vw;
    font-weight: bold;
  }
  .organizeheader {
    width: 93vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 7vw;
    height: 100vh;
  }
  .emotiontext {
    margin-top: 0.5vh;
    font-size: 1.3vw;
    font-weight: bold;
    text-align: center;
  }
  .textandimgemotion {
    width: 5.5vw;
    margin-bottom: 2vw;
  }
  .reactplayer {
    display: none;
  }
}
@media (max-width: 600px) {
  .emotionstired {
    width: 20vw;
    margin-top: 2.2vh;
  }
  .emotiontextcalm {
    margin-top: 0.4vh;
    font-size: 5vw;
    font-weight: bold;
    text-align: center;
  }
  .emotions {
    width: 20vw;
    margin-top: 3vh;
  }
  .innerfeelcol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .innerfeelrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3vh;
  }
  .outerfeel {
    background-color:  #F5FBFF;
    display: flex;
    flex-direction: row;
  }
  .howdoifeelrow {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 10vh;
  }
  .greennumber {
    width: 6vw;
    height: 4vh;
  }
  .howdoifeel {
    color: #4da95a;
    font-size: 6vw;
    font-weight: bold;
    margin-bottom: 0;
  }
  .organizeheader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
  }
  .emotiontext {
    margin-top: 0.5vh;
    font-size: 5vw;
    font-weight: bold;
    text-align: center;
  }
  .textandimgemotion {
    margin-top: 3vh;
  }
  .reactplayer {
    display: none;
  }
}
