/* #emailLoginButton {
  background-color: transparent;
  width: 20vw;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #BBB;
  font-weight: 400;
  padding: 5px;
} */

/* #googleLoginButton {
  background-color: white;
  width: 20vw;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: #767676;
  padding: 5px;
} */

#outerEmailBackground {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 30vw; */
  /* margin-top: 2vh; */
  border-radius: 10px;
  /* padding: 20px; */
}

.emailLoginInputs {
  /* width: 100%;  */
  border: solid;
  border-width: 1px;
  border-color: #000000;
  padding: 10px;
  border-radius: 10px;
  height: 3.8rem;
  width: 32.2rem;
  color: #555555
}

.labelText{
  color: #555555
}

#forgotPass:hover {
  cursor: pointer;
}

/* #emailImage {
  height: 45%;
  width: auto;
  margin-right: 5%;
} */

/* #googleImage {
  height: 45%;
  width: auto;
  margin-right: 5%;
} */

/* #googleImage {
  height: 2rem;
  width: auto;
  margin-right: 1rem;
} */

/* #cancelButton {
  width: 50%;
  padding: 10px;
  border-width: "1px";
  height: 5vh;
  border-color: white;
} */

#signInButton {
  height: 3.8rem;
  width: 32.2rem;
  border-radius: 0.8rem;
  border: 0.1rem solid #000000;
  background-color: #135aaa;
  font-family: "Inter-Regular", Helvetica;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 20px;
  color: white;
}

/* #microsoftButton {
  background-color: white;
  width: 20vw;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: #767676;
  padding: 5px;
} */

/* #cleverButton {
  width: calc(20vw + 10px);
}

#microLogoImg {
  height: 45%;
  width: auto;
  margin-right: 5%;
} */

@media (max-width: 600px) {
  /* #emailLoginButton {
    background-color: #bd91e4;
    width: 50vw;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    padding: 5px;
    font-size: 3.5vw;
  } */

  /* #googleLoginButton {
    background-color: white;
    width: 50vw;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: #767676;
    padding: 5px;
    font-size: 3.5vw;
  } */

  /* #microsoftButton {
    background-color: white;
    width: 50vw;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: #767676;
    padding: 5px;
    font-size: 3.5vw;
  } */

  /* #cleverButton {
    width: calc(50vw + 10px);
  }

  #emailImage {
    height: 25%;
  } */

  /* #googleImage {
    height: 35%;
  } */

  #microLogoImg {
    height: 25%;
  }

  #signInButton {
    /* width: 100%; */
    background-color: #135aaa;
    color: white;
    padding: 10px;
    /* border-width: "1px"; */
    font-size: 2.8vw;
    margin-bottom: 20px;
    cursor: pointer;
  }

  /* #cancelButton {
    width: 50%;
    padding: 10px;
    border-width: "1px";
    font-size: 2.8vw;
    background-color: lightgray;
    color: black;
  } */

  #outerEmailBackground {
    width: 70vw;
  }
}
