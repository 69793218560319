@media(min-width:601px){
    .bluesidebar{
        border-radius: 0px 25px 25px 0px;
        display:flex;
        flex-direction:column;
        width:15vw;
        height:100vh;
        align-items: center;
        background-color:#5b9cd6;
        position:fixed;
    }
    .bluestyleiconstudent{
        font-size: 4.5vw;
        color:white;
        margin-top: 6vh;
        margin-left: 1.5vw;
    }
    .bluestyleiconteacher{
        font-size: 4vw;
        color:white;
        margin-left: 1.5vw;
        margin-top: 4vh;
    }
    .bluestyleiconadmin{
        font-size: 3vw;
        color:white;
        margin-left: 1.5vw;
        margin-top: 2vh;
    }
    .bluelogo{
        /* height:14vh; */
        width:7.5vw;
        margin-top: 4vh;
    }
}

@media(max-width:600px){
    .bluesidebar{
        bottom:0%;
        border-radius: 25px 25px 0px 0px;
        display:flex;
        flex-direction:row;
        width:100vw;
        height:15vh;
        align-items: center;
        background-color:#5b9cd6;
        position:fixed;
    }
    .bluestyleiconstudent{
        font-size: 8vw;
        color:white;
        margin-right: 6vw;
        margin-left: 10vw;
        margin-top: 2vh;
    }
    .bluestyleiconteacher{
        font-size: 8vw;
        color:white;
        margin-right: 4vw;
        margin-left: 7vw;
        margin-top: 2vh;
    }
    .bluestyleiconadmin{
        font-size: 8vw;
        color:white;
        margin-right: 2vw;
        margin-left: 6vw;
        margin-top: 2vh;
    }
    .bluelogo{
        height:0;
    }
}

