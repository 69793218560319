@media (min-width: 601px) {
  .profilecalendar {
    /* margin-left: 8vw; */
    background-color: white;
    /* width: 50vw; */
    height: 70vh;
    /* margin-top: 2vh; */
    margin-bottom: 5vh;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    flex-direction: column;
  }
  .rbc-button-link {
    width: 2vw;
  }
  .rbc-date-cell {
    margin-left: 0.25vw;
    margin-top: 0vh;
    height: 1.5vh;
  }
  .calendarimg {
    height: 4vw;
    /* margin-left: 0.5vw; */
    /* margin-bottom: 2vh; */
  }
}

@media (max-width: 600px) {
  .profilecalendar {
    margin-left: 5vw;
    /* margin-left: 7vw; */
    background-color: white;
    width: 90vw;
    height: 60vh;
    margin-top: 0;
    /* margin-bottom: 10vh; */
    border-radius: 5px 5px 5px 5px;
  }

  .calendarsizer {
    width: 80vw;
  }

  .rbc-button-link {
    width: 2.8vw;
  }
  .rbc-date-cell {
    margin-left: 0.25vw;
    margin-top: 0vh;
    height: 4vh;
    padding-right: 0; 
    text-align: center;
  }
  .calendarimg {
    height: 2vh;
    width: 4vw;
    /* margin-right: 0.5vw; */
    /* margin-bottom: 2vh; */
  }
  .rbc-toolbar .rbc-toolbar-label {
    text-align: center;
  }
}

/* .rbc-btn {
  } */
.rbc-toolbar button {
  visibility: hidden;
}
/* .rbc-calendar *, .rbc-calendar *:before, .rbc-calendar *:after{
} */
.rbc-btn-group {
  height: 0vh;
}
.rbc-calendar {
  margin-left: 4vw;
  margin-top:3vh;
}
.rbc-day-bg {
  background-color: white;
  text-align: left;
}
.rbc-button-link {
  pointer-events: none;
  text-align: left;
}
.rbc-toolbar .rbc-toolbar-label {
  text-align: left;
  margin-top: 2vh;
  font-weight: bolder;
  font-size: 1.25em;
}
.rbc-month-row + .rbc-month-row {
  border-top: 2px solid black;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 2px solid black;
}
.rbc-month-view {
  border: 2px solid black;
}

.rbc-header {
  border-bottom: 2px solid black;
}
.rbc-header + .rbc-header {
  border-left: 2px solid black;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
}
.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
  width: 4vw;
  /* margin-bottom: 2vw; */
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: white;
  padding: 0px 0px 0px 0px;
  width: 3.5vw;
  margin-left: 2vw;
}
.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: white;
}
.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: white;
}

.rbc-row {
  margin-top: 0vh;
}
.rbc-row-content {
  padding: 0px 0px 0px 0px;
}
.rvc-row-segment {
  padding: 0px 0px 0px 0px;
}
